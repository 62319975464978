import * as React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  TextInput,
  DateField,
  DeleteButton,
  ShowButton,
  Filter, useTranslate
} from 'react-admin'

const StaticPagesFilter = (props) => (
  <Filter {...props}>
    <TextInput source='search' alwaysOn />
  </Filter>
)

export const StaticPageList = (props) => {
  const translate = useTranslate()
  return (
    <List title={translate('pos.dashboard.staticPages')} {...props} filters={<StaticPagesFilter />}>
      <Datagrid>
        <TextField source='key' />
        <TextField source='name' />
        <DateField source='createAt' />
        <ShowButton basePath='/staticPages' />
        <EditButton basePath='/staticPages' />
        {
          props.permissions === 'root' && (
              <DeleteButton basePath='/staticPages' />
          )
        }
      </Datagrid>
    </List>
  )
}
