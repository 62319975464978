import * as React from 'react'
import {
  Show,
  SimpleShowLayout,
  ReferenceField,
  BooleanField
} from 'react-admin'
import TextField from './../fields/TextField'

const UserTitle = ({ record }) => {
  return <span>Utilisateur {record ? `"${record.email}"` : ''}</span>
}

export const UserShow = props => {
  return (
    <Show title={<UserTitle />} {...props}>
      <SimpleShowLayout className='show_field_container'>
        <TextField label='resources.users.fields.email' source='email' />
        <TextField label='resources.users.fields.role' source='role' />
        <TextField
          label='resources.users.fields.firstName'
          source='firstName'
        />
        <TextField label='resources.users.fields.lastName' source='lastName' />
        <TextField
          label='resources.users.fields.rppsNumber'
          textAlign='left'
          source='rppsNumber'
        />
        <TextField label='resources.users.fields.civility' source='civility' />
        <ReferenceField
          label='resources.users.fields.profession'
          className='referenceField'
          source='profession'
          reference='metadatas'
        >
          <TextField source='name' noMarginBottom />
        </ReferenceField>
        <TextField
          label='resources.users.fields.exerciceMode'
          source='exerciseMode'
        />

        <TextField label='resources.users.fields.establishment' source='establishment' />

        <TextField
          label='resources.users.fields.businessAddress'
          source='businessAddress'
        />
        <TextField label='resources.users.fields.zipcode' source='zipcode' />
        <TextField label='resources.users.fields.city' source='city' />
        <BooleanField label='resources.users.fields.receiveNewsletter' source='receiveNewsletter' />
      </SimpleShowLayout>
    </Show>
  )
}
