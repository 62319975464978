import * as React from 'react'
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  EditButton,
  DeleteButton,
  ShowButton,
  Filter,
  useTranslate,
  ReferenceField
} from 'react-admin'

const FormationFilter = (props) => (
  <Filter {...props}>
    <TextInput source='search' alwaysOn />
  </Filter>
)

export const FormationList = (props) => {
  const translate = useTranslate()

  return (
    <List title={translate('pos.dashboard.formations')} {...props} filters={<FormationFilter />}>
      <Datagrid>
        <TextField source='title' />
        <TextField source='keyGa' />
        <ReferenceField
          source='formationType'
          reference='metadatas'
          children={<TextField source='name' />}
        />
        <ShowButton basePath='/formations' />
        <EditButton basePath='/formations' />
        <DeleteButton basePath='/formations' />

      </Datagrid>
    </List>
  )
}
