import * as React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  TextInput,
  SelectInput,
  DeleteButton,
  ShowButton,
  Filter,
  useTranslate,
    downloadCSV
} from 'react-admin'

import jsonExport from 'jsonexport/dist';

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput source='search' alwaysOn />
    <SelectInput
      source='role'
      optionText='name'
      choices={[
        { id: 'root', name: 'Root' },
        { id: 'admin', name: 'Admin' },
        { id: 'user', name: 'User' }
      ]}
    />
  </Filter>
)

export const UserList = (props) => {
  const translate = useTranslate()



    const exporter = users => {
        const usersForExport = users.map(user => {
            return {
                id : user.id,
                email : user.email,
                firstname: user.firstName,
                lastname: user.lastName
            }
        });
        jsonExport(usersForExport, {
            headers: ['id', 'firstname', 'lastname', 'email']
        }, (err, csv) => {
            downloadCSV(csv, 'users');
        });
    };



  return (
    <List title={translate('pos.dashboard.users')} {...props} filters={<UserFilter />} exporter={exporter}>
      <Datagrid>
        <TextField source='firstName' />
        <TextField source='lastName' />
        <TextField source='email' />
        <ShowButton basePath='/users' />
        <EditButton basePath='/users' />
        <DeleteButton basePath='/users' />
      </Datagrid>
    </List>
  )
}
