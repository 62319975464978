import * as React from 'react'
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  EditButton,
  SelectInput,
  DeleteButton,
  ShowButton,
  Filter,
  useTranslate
} from 'react-admin'

const MetaDataFilter = (props) => (
  <Filter {...props}>
    <TextInput source='search' alwaysOn />
    <SelectInput
      source='onModel'
      optionText='name'
      choices={[
        { id: 'accompaniment', name: 'Accompagnement' },
        { id: 'formation', name: 'Formation' },
        { id: 'guide', name: 'Guide' },
        { id: 'user', name: 'Utilisateur' }
      ]}
    />
    <SelectInput
      source='onField'
      optionText='name'
      choices={[
        { id: 'profession', name: 'Profession' },
      ]}
    />
  </Filter>
)

export const MetaDataList = (props) => {
  const translate = useTranslate()
  return (
    <List title={translate('pos.dashboard.metadatas')} {...props} filters={<MetaDataFilter />}>
      <Datagrid>
        <TextField source='name' />
        <TextField source='onModel' />
        <TextField source='onField' />
        <ShowButton basePath='/metadatas' />
        <EditButton basePath='/metadatas' />
        <DeleteButton basePath='/metadatas' />

      </Datagrid>
    </List>
  )
}
