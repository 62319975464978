import * as React from 'react'
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  EditButton,
  DeleteButton,
  ShowButton,
  Filter,
  useTranslate
} from 'react-admin'

const EventFilter = (props) => (
  <Filter {...props}>
    <TextInput source='search' alwaysOn />
  </Filter>
)

export const EventList = (props) => {
  const translate = useTranslate()

  return (
    <List title={translate('pos.dashboard.events')} {...props} filters={<EventFilter />}>
      <Datagrid>
        <TextField source='name' />
        <ShowButton basePath='/events' />
        <EditButton basePath='/events' />
        <DeleteButton basePath='/events' />
      </Datagrid>
    </List>
  )
}
