const FormError = ({ error }) => {
  if (!error) {
    return null
  }

  return (
    <div>
      {error.message}
    </div>
  )
}

export default FormError
