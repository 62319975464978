import { stringify } from 'query-string'
import { fetchUtils } from 'ra-core'
import uploadMedias from './uploadMedias'

let __assign = (this && this.__assign) || function () {
  __assign = Object.assign || function (t) {
    for (let s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i]
      for (const p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) { t[p] = s[p] }
      }
    }
    return t
  }
  return __assign.apply(this, arguments)
}

const gen_url = function (apiUrl, resource, query) {
  return apiUrl + '/' + resource + '?' + stringify(query)
}

/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 *
 * @example
 *
 * getList          => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * getOne           => GET http://my.api.url/posts/123
 * getManyReference => GET http://my.api.url/posts?author_id=345
 * getMany          => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * create           => POST http://my.api.url/posts/123
 * update           => PUT http://my.api.url/posts/123
 * updateMany       => PUT http://my.api.url/posts/123, PUT http://my.api.url/posts/456, PUT http://my.api.url/posts/789
 * delete           => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 * import jsonServerProvider from 'ra-data-json-server';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={jsonServerProvider('http://jsonplaceholder.typicode.com')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */

export default (function (apiUrl, httpClient) {
  if (httpClient === void 0) { httpClient = fetchUtils.fetchJson }
  return ({
    getList: function (resource, params) {
      console.log(params.filter)
      const _a = params.pagination; const page = _a?.page; const perPage = _a?.perPage
      const _b = params.sort; const field = _b?.field; const order = _b?.order
      const query = __assign(__assign({}, fetchUtils.flattenObject(params.filter)), { _sort: field, _order: order, _start: (page - 1) * perPage, _end: page * perPage })
      return httpClient(gen_url(apiUrl, resource, query)).then(function (_a) {
        const headers = _a.headers; const json = _a.json
        if (!headers.has('x-total-count')) {
          throw new Error('The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?')
        }

        if (headers.get('x-api-key') && headers.get('x-refresh-token') && headers.get('x-expire-refresh')) {
          localStorage.setItem('xExpireRefresh', headers.get('x-expire-refresh'))
          localStorage.setItem('xApiKey', headers.get('x-api-key'))
          localStorage.setItem('xRefreshToken', headers.get('x-refresh-token'))
        }
        return {
          data: json,
          total: parseInt(headers.get('x-total-count').split('/').pop(), 10)
        }
      })
    },
    getOne: function (resource, params) {
      return httpClient(apiUrl + '/' + resource + '/' + params.id).then(function (_a) {
        const json = _a.json
        return ({
          data: json
        })
      })
    },
    getMany: function (resource, params) {
      const query = {
        id: params.ids
      }
      return httpClient(gen_url(apiUrl, resource, query)).then(function (_a) {
        const json = _a.json
        return ({ data: json })
      })
    },
    getManyReference: function (resource, params) {
      let _a
      const _b = params.pagination; const page = _b.page; const perPage = _b.perPage
      const _c = params.sort; const field = _c.field; const order = _c.order
      const query = __assign(__assign({}, fetchUtils.flattenObject(params.filter)), (_a = {}, _a[params.target] = params.id, _a._sort = field, _a._order = order, _a._start = (page - 1) * perPage, _a._end = page * perPage, _a))
      return httpClient(gen_url(apiUrl, resource, query)).then(function (_a) {
        const headers = _a.headers; const json = _a.json
        if (!headers.has('x-total-count')) {
          throw new Error('The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?')
        }
        return {
          data: json,
          total: parseInt(headers.get('x-total-count').split('/').pop(), 10)
        }
      })
    },
    update: async function (resource, params) {
      return uploadMedias(params.data)
        .then(_datas => {
          return httpClient(apiUrl + '/' + resource + '/' + params.id, {
            method: 'PUT',
            body: JSON.stringify(_datas)
          }).then(function (_a) {
            const json = _a.json
            return ({
              data: json
            })
          })
        })
    },
    // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    updateMany: function (resource, params) {
      return Promise.all(params.ids.map(function (id) {
        return httpClient(apiUrl + '/' + resource + '/' + id, {
          method: 'PUT',
          body: JSON.stringify(params.data)
        })
      })).then(function (responses) {
        return ({
          data: responses.map(function (_a) {
            const json = _a.json
            return json.id
          })
        })
      })
    },
    create: async function (resource, params) {
      return uploadMedias(params.data)
        .then(_datas => {
          return httpClient(apiUrl + '/' + resource, {
            method: 'POST',
            body: JSON.stringify(_datas)
          }).then(function (_a) {
            const json = _a.json
            return ({
              data: __assign(__assign({}, _datas), { id: json.id })
            })
          })
        })
    },
    delete: function (resource, params) {
      return httpClient(apiUrl + '/' + resource + '/' + params.id, {
        method: 'DELETE'
      }).then(function (_a) {
        const json = _a.json
        return ({ data: json })
      })
    },
    // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: function (resource, params) {
      return Promise.all(params.ids.map(function (id) {
        return httpClient(apiUrl + '/' + resource + '/' + id, {
          method: 'DELETE'
        })
      })).then(function (responses) {
        return ({
          data: responses.map(function (_a) {
            const json = _a.json
            return json.id
          })
        })
      })
    }
  })
})
