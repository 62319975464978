import React from 'react'

const SimpleItemField = ({ record }) => {
  return record ? (
    <div>
      <span>{'- ' + record}</span>
    </div>
  ) : null
}

SimpleItemField.defaultProps = {
  addLabel: true
}

export default SimpleItemField
