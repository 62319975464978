import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'

const TextField = (props) => {
  const translate = useTranslate()
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start'
    }}
    >
      <Typography variant='overline' component='p' className='top-label'>
        { _.get(props, 'addLabel',  false) === false && translate(props.label)}
      </Typography>
      <Typography
        style={{
          marginBottom: props.noMarginBottom ? 6 :( typeof _.get(props, 'contentMarginBottom') === "number"  ? props.contentMarginBottom : null)
        }} component='p' variant='subtitle2' paragraph
      >
        {
          props.isDate ? new moment(props.record[props.source]).format('DD-MM-YYYY') : props.record[props.source] ? props.record[props.source] : '-'
        }
      </Typography>
    </div>
  )
}

TextField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired
}

export default TextField
