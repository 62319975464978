import * as React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  ArrayInput,
  SelectInput,
  SimpleFormIterator,
  BooleanInput,
  FormDataConsumer,
  useTranslate,
  FileInput,
  FileField,
  maxLength,
  NumberInput,
  maxValue,
  minValue,
  ReferenceInput
} from 'react-admin'
import _ from 'lodash'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

export const GuideCreate = (props) => {
  const translate = useTranslate()

  return (
    <Create {...props}>
      <SimpleForm>
        <div className='form_field_container'>
          <div className='field_container'>
            <TextInput source='name' />
            <TextInput
                label={translate('resources.guides.fields.keyGa')}
                source='keyGa'
            />
            <ReferenceInput
              label={translate('resources.guides.fields.guideType')} source='guideType'
              reference='metadatas'
              filterToQuery={q => {
                return {
                  onModel: 'guide'
                }
              }} link={false}
            >
              <SelectInput optionText='name' />
            </ReferenceInput>
          </div>
        </div>
      </SimpleForm>
    </Create>
  )
}
