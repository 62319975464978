// in src/Dashboard.js
import * as React from 'react'
import Card from '@material-ui/core/Card'
import UserIcon from '@material-ui/icons/People'
import NoteIcon from '@material-ui/icons/Note'
import SchoolIcon from '@material-ui/icons/School'
import EventIcon from '@material-ui/icons/Event'
import FaceIcon from '@material-ui/icons/Face'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import SettingsIcon from '@material-ui/icons/Settings'
import DescriptionIcon from '@material-ui/icons/Description'
import WcIcon from '@material-ui/icons/Wc'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import {Link} from 'react-router-dom'
import {useTranslate} from 'react-admin'

export const Dashboard = (props) => {
    console.log('-');
    console.log(props);
    console.log('-');

    const translate = useTranslate()
    return (
        <div style={{
            height: '100%',
            display: 'grid',
            gridTemplateColumns: '30% 30% 30%',
            padding: '100px'

        }}
        >

            <Link
                to={{
                    pathname: '/news'
                }}
                style={{
                    textDecoration: 'none'
                }}
            >
                <Card className='dashboard_card'>
                    <NewReleasesIcon style={{fontSize: 100, color: '#535c68'}}/>
                    <h4 style={{color: '#535c68'}}>{translate('pos.dashboard.news')}</h4>
                </Card>
            </Link>

            <Link
                to={{
                    pathname: '/staticPages'
                }}
                style={{
                    textDecoration: 'none'
                }}
            >
                <Card className='dashboard_card'>
                    <DescriptionIcon style={{fontSize: 100, color: '#535c68'}}/>
                    <h4 style={{color: '#535c68'}}>{translate('pos.dashboard.staticPages')}</h4>
                </Card>
            </Link>

            <Link
                to={{
                    pathname: '/metadatas'
                }}
                style={{
                    textDecoration: 'none'
                }}
            >
                <Card className='dashboard_card'>
                    <NoteIcon style={{fontSize: 100, color: '#535c68'}}/>
                    <h4 style={{color: '#535c68'}}>{translate('pos.dashboard.metadatas')}</h4>
                </Card>
            </Link>

            <Link
                to={{
                    pathname: '/guides'
                }}
                style={{
                    textDecoration: 'none'
                }}
            >
                <Card className='dashboard_card'>
                    <MenuBookIcon style={{fontSize: 100, color: '#535c68'}}/>
                    <h4 style={{color: '#535c68'}}>{translate('pos.dashboard.guides')}</h4>
                </Card>
            </Link>
            <Link
                to={{
                    pathname: '/committee'
                }}
                style={{
                    textDecoration: 'none'
                }}
            >
                <Card className='dashboard_card'>
                    <WcIcon style={{fontSize: 100, color: '#535c68'}}/>
                    <h4 style={{color: '#535c68'}}>{translate('pos.dashboard.committee')}</h4>
                </Card>
            </Link>
            <Link
                to={{
                    pathname: '/formations'
                }}
                style={{
                    textDecoration: 'none'
                }}
            >
                <Card className='dashboard_card'>
                    <SchoolIcon style={{fontSize: 100, color: '#535c68'}}/>
                    <h4 style={{color: '#535c68'}}>{translate('pos.dashboard.formations')}</h4>
                </Card>
            </Link>

            <Link
                to={{
                    pathname: '/events'
                }}
                style={{
                    textDecoration: 'none'
                }}
            >
                <Card className='dashboard_card'>
                    <EventIcon style={{fontSize: 100, color: '#535c68'}}/>
                    <h4 style={{color: '#535c68'}}>{translate('pos.dashboard.events')}</h4>
                </Card>
            </Link>

            {

                props.permissions === 'root' && (
                    <Link
                        to={{
                            pathname: '/users'
                        }}
                        style={{
                            textDecoration: 'none'
                        }}
                    >
                        <Card className='dashboard_card'>

                            <UserIcon style={{fontSize: 100, color: '#535c68'}}/>
                            <h4 style={{color: '#535c68'}}>{translate('pos.dashboard.users')}</h4>
                        </Card>
                    </Link>
                )

            }
            <Link
                to={{
                    pathname: '/accompaniments'
                }}
                style={{
                    textDecoration: 'none'
                }}
            >
                <Card className='dashboard_card'>
                    <SupervisedUserCircleIcon style={{fontSize: 100, color: '#535c68'}}/>
                    <h4 style={{color: '#535c68'}}>{translate('pos.dashboard.accompaniments')}</h4>
                </Card>
            </Link>
            <Link
                to={{
                    pathname: '/me'
                }}
                style={{
                    textDecoration: 'none'
                }}
            >
                <Card className='dashboard_card'>
                    <FaceIcon style={{fontSize: 100, color: '#535c68'}}/>
                    <h4 style={{color: '#535c68'}}>{translate('pos.dashboard.me')}</h4>
                </Card>
            </Link>

            <Link
                to={{
                    pathname: '/settings'
                }}
                style={{
                    textDecoration: 'none'
                }}
            >
                <Card className='dashboard_card'>
                    <SettingsIcon style={{fontSize: 100, color: '#535c68'}}/>
                    <h4 style={{color: '#535c68'}}>{translate('pos.dashboard.settings')}</h4>
                </Card>
            </Link>


        </div>

    )

}
