import FormField from './FormField'
import FormError from './FormError'
import FormInput from './FormInput'
import Select from 'react-select'
import _ from 'lodash'
import { useFieldArray, Controller } from 'react-hook-form'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'

const ArrowInput = ({ control, indexLevel, errors, defaultLine, register }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `level[${indexLevel}].${defaultLine}`
  })

  const options = {
    position: [
      { _id: 'left', name: 'A gauche' },
      { _id: 'right', name: 'A droite' },
      { _id: 'center', name: 'Centrer' }
    ],
    type: [
      { _id: 'positive', name: 'Positif' },
      { _id: 'negative', name: 'Negatif' },
      { _id: 'standard', name: 'Normal' }
    ],
    typePrincipalReturnLines: [
      { _id: 'left', name: 'Gauche' },
      { _id: 'right', name: 'Droite' },
      { _id: 'standard', name: 'Normal' }
    ]
  }
  const styles = {
    formField: {
      width: '30%',
      margin: '10px 0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  }
  return (

    <div style={{
      padding: 0,
      width: '100%',
      border:'.5px solid grey'
    }}>
      {_.map(fields, (item, index) => {
        const defaultPosition = _.filter(options.position, pos => pos._id === item?.position)
        const defaultType = _.filter(options.type, type => type._id === item?.type)
        const defaultTypePrincipalReturnLine = _.filter(options.typePrincipalReturnLines, type => type._id === item?.type)

        return (
          <div key={item?.id} style={{
            borderBottom: index !== (fields.length -1) ? '.5px solid grey': null
          }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <h3> Fleche {(index + 1)}</h3>
            </div>

            <div style={{
              display: 'flex',
              justifyContent:'space-between'
            }}>
              <FormField id={`level[${indexLevel}].${defaultLine}[${index}].info`} label='Information'  style={styles.formField}>
                <FormInput
                    name={`level[${indexLevel}].${defaultLine}[${index}].info`}
                    type='text'
                    ref={register}
                    defaultValue={_.get(item, 'info', '')}
                    rules={{
                      maxLength: {
                        value: 30,
                        message: 'Maximum 30 caracteres'
                      }
                    }}
                    control={control}
                />
                <FormError error={_.get(errors, ['level', indexLevel, defaultLine, index, 'info'])} />
              </FormField>
              {
                defaultLine === 'principalReturnLines' ? (
                    <FormField id={`level[${indexLevel}].${defaultLine}[${index}].position`} label='Position' style={styles.formField}>
                      <FormInput
                          name={`level[${indexLevel}].${defaultLine}[${index}].position`}
                          type='number'
                          defaultValue={item?.position}
                          rules={{
                            required: {
                              value: true,
                              message: 'Ce champ est requis'
                            },
                            maxValue: {
                              value: 3,
                              message: 'Maximum 3 routes'
                            },
                            minValue: {
                              value: 1,
                              message: 'La route minimum est 1'
                            }
                          }}
                          control={control}
                      />
                      <FormError error={_.get(errors, ['level', indexLevel, defaultLine, index, 'position'])} />
                    </FormField>
                ) : (
                    <FormField id={`level[${indexLevel}].${defaultLine}[${index}].position`} label='Position'  style={styles.formField}>
                      <Controller
                          control={control}
                          defaultValue={item?.position}
                          name={`level[${indexLevel}].${defaultLine}[${index}].position`}
                          rules={{
                            required: {
                              value: true,
                              message: 'Ce champ est requis'
                            }
                          }}
                          render={(
                              { onChange, ref }
                          ) => (
                              <Select
                                  getOptionLabel={option => option.name}
                                  getOptionValue={option => option._id}
                                  options={options.position}
                                  defaultValue={defaultPosition}
                                  onChange={selected => onChange(selected._id)}
                                  inputRef={ref}
                              />
                          )}
                      />
                      <FormError error={_.get(errors, ['level', indexLevel, defaultLine, index, 'position'])} />
                    </FormField>
                )
              }

              <FormField id={`level[${indexLevel}].${defaultLine}[${index}].type`} label='Type'  style={styles.formField}>
                <Controller
                    control={control}
                    defaultValue={item?.type}
                    name={`level[${indexLevel}].${defaultLine}[${index}].type`}
                    rules={{
                      required: {
                        value: true,
                        message: 'Ce champ est requis'
                      }
                    }}
                    render={(
                        { onChange, ref }
                    ) => (
                        <Select
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option._id}
                            options={defaultLine === 'principalReturnLines' ? options.typePrincipalReturnLines : options.type}
                            defaultValue={defaultLine === 'principalReturnLines' ? defaultTypePrincipalReturnLine : defaultType}
                            onChange={selected => onChange(selected._id)}
                            inputRef={ref}
                        />
                    )}
                />
                <FormError error={_.get(errors, ['level', indexLevel, defaultLine, index, 'type'])} />
              </FormField>

            </div>

            <Button
                color='primary'
                onClick={() => {
                  remove(index)
                }}
            >
              <DeleteIcon />
            </Button>
          </div>

        )
      })}


      {
        fields.length < 2
          ? (
            <div style={{
              margin: 20,
              display: 'flex',
              justifyContent:'center'
            }}>
              <Button
                  color='secondary'
                  variant='contained'
                  onClick={() => {
                    append({})
                  }}
              >
                <AddIcon />
              </Button>
            </div>
          ) : ''
      }
    </div>
  )
}

export default ArrowInput
