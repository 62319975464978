import * as React from 'react'
import {
  Show,
  SimpleShowLayout,
  RichTextField,
  ImageField
} from 'react-admin'
import TextField from '../fields/TextField'

const NewsTitle = ({ record }) => {
  return <span>Actualité {record ? `"${record.title}"` : ''}</span>
}

export const NewsShow = (props) => (
  <Show title={<NewsTitle />} {...props}>
    <SimpleShowLayout className='show_field_container'>
      <TextField label='resources.news.fields.title' source='title' />
      <TextField label='resources.news.fields.type' source='type' />
      <TextField label='resources.news.fields.resume' source='resume' />
      <TextField label='resources.news.fields.expirationDate' isDate source='expirationDate' />
      <ImageField
        label='resources.news.fields.mainImage'
        source='mainImage.src'
      />
      <RichTextField className='rich-text' source='content' />

    </SimpleShowLayout>
  </Show>
)
