import * as React from 'react'
import {
  Create,
  SimpleForm,
  ImageInput,
  NumberInput,
  ImageField,
  TextInput,
  useTranslate
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'

export const CommitteeCreate = (props) => {
  const translate = useTranslate()

  return (
    <Create {...props}>
      <SimpleForm enctype='multipart/form-data'>
        <div
          className='form_field_container' style={{
            flexDirection: 'column'
          }}
        >
          <div className='field_container'>
            <TextInput label={translate('resources.committee.fields.name')} source='name' />
            <TextInput label={translate('resources.committee.fields.function')} source='function' />
            <NumberInput label={translate('resources.committee.fields.order')} source='order' />
            <ImageInput
              label={translate('resources.committee.fields.mainImage')}
              source='mainImage'
              accept={[
                '.jpg',
                '.jpeg',
                '.JPG',
                '.png',
                '.gif'
              ]}
              labelSingle={translate('resources.committee.fields.uploadImage')}
              maxSize={5000000}
              children={<ImageField />}
            />

          </div>
          <RichTextInput
            label={translate('resources.committee.fields.content')}
            source='content' toolbar={[
              ['bold', 'italic', 'underline'], // toggled buttons
              ['link', 'image', 'video'],
              [{ list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
              [{ header: [2, 3, false] }],
              ['clean'] // remove formatting button
            ]}
          />
        </div>
      </SimpleForm>
    </Create>
  )
}

/*
<RichTextInput source="body" />
            <DateInput label="Publication date" source="published_at" defaultValue={new Date()} />
 */
