import * as React from 'react'
import {
  List, Datagrid, TextField, TextInput, EditButton,
  DeleteButton,
  ShowButton,
  Filter,
  useTranslate,
  ReferenceField
} from 'react-admin'

const AccompanimentFilter = (props) => (
  <Filter {...props}>
    <TextInput source='search' alwaysOn />
  </Filter>
)

export const AccompanimentList = (props) => {
  const translate = useTranslate()

  return (
    <List title={translate('pos.dashboard.formations')} {...props} filters={<AccompanimentFilter />}>
      <Datagrid>
        <TextField source='title' />
        <TextField source='keyGa' />
        <ReferenceField
          source='accompanimentType'
          reference='metadatas'
          children={<TextField source='name' />}
        />
        <ShowButton basePath='/accompaniments' />
        <EditButton basePath='/accompaniments' />
        <DeleteButton basePath='/accompaniments' />

      </Datagrid>
    </List>
  )
}
