import { fetchUtils } from 'react-admin'

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }
  const xApiKey = localStorage.getItem('xApiKey')
  const xRefreshToken = localStorage.getItem('xRefreshToken')
  options.headers.set('x-api-key', `${xApiKey}`)
  options.headers.set('x-refresh-token', `${xRefreshToken}`)
  return fetchUtils.fetchJson(url, options)
}

export default httpClient
