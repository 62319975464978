import * as React from 'react'
import Promise from 'bluebird'
import _ from 'lodash'
import axios from '../../libs/axios'
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  DateInput,
  ReferenceInput,
  AutocompleteArrayInput,
  SimpleFormIterator,
  ArrayInput,
  RadioButtonGroupInput,
  useTranslate,
  FormDataConsumer
} from 'react-admin'
import { useEffect, useState } from 'react'

export const EventCreate = (props) => {
  const translate = useTranslate()

  const [participantsChoices, setParticipantsChoices] = useState()

  useEffect(() => {
    const getUserParams = {
      _end: 10,
      _order: 'ASC',
      _sort: 'id',
      _start: 0
    }

    Promise.props({
      participants: axios.get('/users', { params: getUserParams })
    }).then(res => {
      const users = _.get(res, 'participants.data', [])
      if (users.length > 0) {
        setParticipantsChoices(users)
      }
    })
  }, [])

  return (
    <Create {...props}>
      <SimpleForm>
        <div className='form_field_container'>
          <div className='field_container'>
            <TextInput
              label={translate('resources.events.fields.name')}
              source='name'
            />

            <RadioButtonGroupInput
              label={translate('resources.events.fields.eventType')}
              source='eventType'
              choices={[
                { id: 'formation', name: 'Formation' },
                { id: 'accompaniment', name: 'Accompagnement' }
              ]}
              style={{
                textAlign: 'left'
              }}
              initialValue='formation'
            />
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                if (formData.eventType === 'formation') {
                  formData.accompanimentLink = null
                } else {
                  formData.formationLink = null
                }
                return (
                  formData.eventType === 'formation' ? (
                    <ReferenceInput
                      label={translate('resources.events.fields.formation')}
                      source='formationLink' reference='formations'
                    >
                      <SelectInput optionText='title' />
                    </ReferenceInput>
                  ) : (
                    <ReferenceInput
                      label={translate('resources.events.fields.accompaniment')}
                      source='accompanimentLink' reference='accompaniments'
                    >
                      <SelectInput optionText='title' />
                    </ReferenceInput>
                  )
                )
              }}
            </FormDataConsumer>

            <DateInput
              label={translate('resources.events.fields.startAt')}
              source='startAt'
              initialValue={new Date()}
            />
            <TextInput
              label={translate('resources.events.fields.location')}
              source='location'
            />
            <TextInput
              label={translate('resources.events.fields.duration')}
              source='duration'
            />
            <TextInput
              label={translate('resources.events.fields.dateInfo')}
              source='dateInfo'
            />
            <TextInput
              label={translate('resources.events.fields.targetPublic')}
              source='targetPublic'
            />
            <ArrayInput label={translate('resources.events.fields.goals')} source='goals'>
              <SimpleFormIterator>
                <TextInput label={translate('resources.events.fields.name')} source='' />
              </SimpleFormIterator>
            </ArrayInput>
            {
              participantsChoices ? (
                <AutocompleteArrayInput
                  label={translate('resources.events.fields.participants')}
                  source='participants'
                  optionText='email'
                  fullWidth
                  choices={participantsChoices}
                />
              ) : null
            }
          </div>
        </div>
      </SimpleForm>
    </Create>
  )
}
