import * as React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  Datagrid,
  ArrayField,
  ReferenceField,
  BooleanField
} from 'react-admin'

const GuideTitle = ({ record }) => {
  return <span>Guide {record ? `"${record.name}"` : ''}</span>
}

export const GuideShow = (props) => (
  <Show title={<GuideTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source='name' />
      <TextField source='keyGa' />
      <ReferenceField
        link='show'
        source='guideType' reference='metadatas'
      >
        <TextField source='name' noMarginBottom />
      </ReferenceField>


      <BooleanField source='display' />


      <ArrayField source='level'>
        <Datagrid>
          <TextField source='elementPositionId' />
          <ArrayField source='lastLines'>
            <Datagrid>
              <TextField source='position' />
              <TextField source='type' />
              <TextField source='info' />
            </Datagrid>
          </ArrayField>
          <ArrayField source='returnLines'>
            <Datagrid>
              <TextField source='position' />
              <TextField source='type' />
              <TextField source='info' />
            </Datagrid>
          </ArrayField>
          <ArrayField source='principalReturnLines'>
            <Datagrid>
              <TextField source='position' />
              <TextField source='type' />
              <TextField source='info' />
            </Datagrid>
          </ArrayField>
          <ArrayField source='standardLines'>
            <Datagrid>
              <TextField source='position' />
              <TextField source='type' />
              <TextField source='info' />
            </Datagrid>
          </ArrayField>
          <ArrayField source='blocks'>
            <Datagrid>
              <TextField source='advice' />
              <TextField source='blockLevel' />
              <BooleanField source='booleanConsensus' />
              <BooleanField source='booleanFormation' />
              <BooleanField source='booleanInformation' />
              <BooleanField source='booleanVideo' />
              <BooleanField source='booleanDownload' />
            </Datagrid>
          </ArrayField>
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
)
