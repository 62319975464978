import * as React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  DateInput,
  useTranslate,
  SelectInput
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'

export const NewsCreate = props => {
  const translate = useTranslate()

  return (
    <Create {...props}>
      <SimpleForm enctype='multipart/form-data'>
        <div
          className='form_field_container'
          style={{
            flexDirection: 'column'
          }}
        >
          <div className='field_container'>
            <TextInput
              label={translate('resources.news.fields.title')}
              source='title'
            />
            <SelectInput
              source='type'
              optionText='name'
              choices={[
                { id: 'recherche', name: 'Recherche' },
                { id: 'formation', name: 'Formation' },
                { id: 'autres', name: 'Autres' }
              ]}
            />
            <TextInput
              label={translate('resources.news.fields.resume')}
              source='resume'
            />
            <DateInput
              label={translate('resources.news.fields.expirationDate')}
              source='expirationDate'
            />
            <ImageInput
              label={translate('resources.news.fields.mainImage')}
              source='mainImage'
              accept={['.jpg', '.jpeg', '.JPG', '.png', '.gif']}
              labelSingle={translate('resources.news.fields.uploadImage')}
              maxSize={5000000}
              children={<ImageField />}
            />
          </div>
          <RichTextInput
            label={translate('resources.news.fields.content')}
            source='content'
            toolbar={[
              ['bold', 'italic', 'underline'], // toggled buttons
              ['link', 'image'],
              [{ list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
              [{ header: [2, 3, false] }],
              ['clean'] // remove formatting button
            ]}
          />
        </div>
      </SimpleForm>
    </Create>
  )
}
