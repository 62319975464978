import * as React from 'react'
import {
  Show,
  SimpleShowLayout,
  RichTextField,
  ImageField, Datagrid, ArrayField, UrlField
} from 'react-admin'
import TextField from './../fields/TextField'
import SimpleItemField from "../fields/SimpleItemField";

const StaticPageTitle = ({ record }) => {
  return <span>Page {record ? `"${record.name}"` : ''}</span>
}

export const StaticPageShow = (props) => (
  <Show title={<StaticPageTitle />} {...props}>
    <SimpleShowLayout className='show_field_container'>
      <ImageField
        source='mainImage.src'
        label={'resources.staticPages.fields.mainImage'}
      />
      <TextField label='resources.staticPages.fields.key' source='key' />
      <TextField label='resources.staticPages.fields.name' source='name' />
      <RichTextField source='content' />


      <ArrayField source="attachedPdfs" label={'resources.staticPages.fields.attachedPdfs'}   >
        <Datagrid>
          <TextField source="title" label={'resources.staticPages.fields.name'} addLabel={false} contentMarginBottom={0} />
          <UrlField source="src" label={'resources.staticPages.fields.url'} addLabel={false} target={'_blank'} />
        </Datagrid>
      </ArrayField>


    </SimpleShowLayout>
  </Show>
)
