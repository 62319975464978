import * as React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  ShowButton,
  useTranslate,
  NumberField
} from 'react-admin'

export const CommitteeList = (props) => {
  const translate = useTranslate()
  return (
    <List title={translate('pos.dashboard.committee')} {...props}>
      <Datagrid>
        <TextField source='name' />
        <TextField source='function' />
        <NumberField source='order' />
        <ShowButton basePath='/committee' />
        <EditButton basePath='/committee' />
        <DeleteButton basePath='/committee' />
      </Datagrid>
    </List>
  )
}
