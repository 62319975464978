import * as React from 'react'
import {
    Show,
    SimpleShowLayout,
    ReferenceField,
    SingleFieldList,
    ArrayField,
    ChipField,
    ReferenceArrayField,
    Datagrid,
    ShowController
} from 'react-admin'
import SimpleItemField from '../fields/SimpleItemField'
import TextField from './../fields/TextField'
import _ from 'lodash'

const EventTitle = ({record}) => {
    return <span>Event {record ? `"${record.name}"` : ''}</span>
}

export const EventShow = (props) => (
    <ShowController {...props}>
        {
            controllerProps => {
                return (
                    <Show title={<EventTitle/>} {...props}>
                        <SimpleShowLayout className='show_field_container'>
                            <TextField label='resources.events.fields.name' source='name'/>
                            {
                                _.get(controllerProps, 'record.formationLink', false) ? (
                                    <ReferenceField
                                        label='resources.events.fields.formation' className='referenceField'
                                        source='formationLink' reference='formations'
                                        link='show'
                                    >
                                        <TextField source='title' noMarginBottom/>
                                    </ReferenceField>
                                ) : null
                            }


                            {
                                _.get(controllerProps, 'record.accompanimentLink', false) ? (
                                    <ReferenceField
                                        label='resources.events.fields.accompaniment' className='referenceField'
                                        source='accompanimentLink' reference='accompaniments'
                                        link='show'
                                    >
                                        <TextField source='title' noMarginBottom/>
                                    </ReferenceField>
                                ) : null
                            }

                            <TextField label='resources.events.fields.startAt' isDate source='startAt'/>
                            <TextField label='resources.events.fields.location' source='location'/>
                            <TextField label='resources.events.fields.duration' source='duration'/>
                            <TextField label='resources.events.fields.dateInfo' source='dateInfo'/>
                            <TextField label='resources.events.fields.targetPublic' source='targetPublic'/>
                            <ArrayField source='goals' className='dataGridField'>
                                <Datagrid>
                                    <SimpleItemField/>
                                </Datagrid>
                            </ArrayField>

                            <ReferenceArrayField source="participants" reference='users' target='_id'>
                                <SingleFieldList>
                                    <ChipField source='email'/>
                                </SingleFieldList>
                            </ReferenceArrayField>

                        </SimpleShowLayout>
                    </Show>
                )
            }
        }
    </ShowController>
)

/*

            <RichTextField source="body" />
            <DateField label="Publication date" source="created_at" />
 */
