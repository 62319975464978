import axios from 'axios'
import _ from 'lodash'
import cookie from 'js-cookie'
// import store from '../redux/store'
import moment from 'moment'
import Promise from 'bluebird'

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACK_URL,
  crossdomain: true
})

// const state = store.getState()

// Sentry.init({ dsn: 'https://2e91a78cef7246888d703ca6914d490a@sentry.io/3703574', environment: 'pwa-local' })
// Add a request interceptor
instance.interceptors.request.use(function (config) {
  // Do something before request is sent

  // config.url = state.constants.backUrl + config.url
  if (!config.url.includes('authenticate')) {
    /*
        config.headers.apiKey = cookie.get('apiKey')
        config.headers.refreshToken = cookie.get('refreshToken')
         */

    _.set(config, 'headers.x-api-key', localStorage.getItem('xApiKey'))
    _.set(config, 'headers.x-refresh-token', localStorage.getItem('xRefreshToken'))
  }
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})
// Add a response interceptor
instance.interceptors.response.use(function (response) {
  // Do something with response data
  if (_.get(response.headers, 'x-api-key', '').length > 0) {
    const expireRefreshDate = moment(new Date(response.headers['x-expire-refresh'])).diff(moment(), 'days') + 1
    cookie.set('apiKey', response.headers['x-api-key'], { expires: expireRefreshDate })
    cookie.set('refreshToken', response.headers['x-refresh-token'], { expires: expireRefreshDate })
    cookie.set('expireRefresh', response.headers['x-expire-refresh'], { expires: expireRefreshDate })
  }
  return response
}, function (error) {
  console.log('AXIOS ERROR : ', error)
})
export default instance
