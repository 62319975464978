import * as React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  TextInput,
  ReferenceField,
  DeleteButton,
  ShowButton,
  Filter
} from 'react-admin'

const GuideFilter = (props) => (
  <Filter {...props}>
    <TextInput source='search' alwaysOn />
  </Filter>
)

export const GuideList = (props) => (
  <List title='guide' {...props} filters={<GuideFilter />}>
    <Datagrid>
      <TextField source='name' />
      <TextField source='keyGa' />
      <ReferenceField
        source='guideType'
        reference='metadatas'
        children={<TextField source='name' />}
      />
      <ShowButton basePath='/guides' />
      <EditButton basePath='/guides' />
      <DeleteButton basePath='/guides' />

    </Datagrid>
  </List>
)
