import React, {useEffect, useState} from 'react'
import {
    FileField,
    FileInput,
    TextInput,
    useTranslate,
    Create,
    SimpleForm,
    Button,
    Loading,
    useNotify
} from 'react-admin'
import axios from '../../libs/axios';


export const Settings = (props) => {

    let translate = useTranslate()
    const notify = useNotify()

    let [rppsDb, setRppsDb] = useState();
    let [rppsSpinner, setRppsSpinner] = useState(false)
    useEffect(() => {
    }, [rppsDb, rppsSpinner])

    const send = (e) => {
        e.preventDefault();
        console.log(rppsDb);
        if (!rppsDb) {
            notify('Veuillez choisir le nouveau fichier rpps.zip')
        } else {
            setRppsSpinner(true);
            const data = new FormData();
            data.append("file", rppsDb)
            axios.post('/settings/rppsDb', data).then(res => {
                notify('Le Base de données des RPPS est à jour, elle sera activé dans quelques minutes ( 10 min max )')
                setRppsSpinner(false);
            }).catch(err => {
                notify('Erreur serveur')
            })
        }

    }
    return (
        <section style={{
            height: '100%',
            overflow: 'scroll',
            color: 'grey',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            <h1>{translate('resources.settings.name')}</h1>

            <section style={{
                border: '.5px solid',
                color: 'grey',
                textAlign: 'left',
                padding: '0px 1%',
                width: '80%',
                height: '200px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                alignItems: 'flex-start'
            }}>
                <div style={{
                    height: '30%'
                }}>
                    <h4>{translate('resources.settings.rppsForm')}</h4>
                </div>
                <div style={{
                    height: '70%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>

                    {
                        !rppsSpinner ? (
                            <>
                                <input
                                    style={{
                                        margin: '5px 0px'
                                    }}
                                    type="file"
                                    id="file"
                                    accept={'.zip'}
                                    onChange={e => {
                                        let file = e.target.files[0];
                                        if (file) {
                                            if (file.name !== 'rpps.zip') {
                                                notify('le fichier doit être nommé rpps.zip')
                                                e = null;
                                                setRppsDb(null)
                                            } else {
                                                setRppsDb(file);
                                            }
                                        } else (
                                            setRppsDb(null)
                                        )

                                    }}/>
                                <Button
                                    style={{
                                        margin: '5px 0px'
                                    }}
                                    variant={'contained'}
                                    onClick={e => {
                                        send(e)
                                    }}
                                    value={'submit'}
                                    label={'Enregistrer'}
                                />
                            </>
                        ) : (
                            <Loading loadingPrimary="" loadingSecondary=""/>
                        )
                    }
                </div>
            </section>
        </section>
    )
}