import React, { useState } from 'react'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import TextField from '@material-ui/core/TextField'
import { useNotify, Notification, useTranslate } from 'react-admin'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import axios from '../../libs/axios'

const ForgotPasswordComponent = (props) => {
  const [email, setEmail] = useState('')

  const notify = useNotify()
  const translate = useTranslate()
  const submit = e => {
    e.preventDefault()
    axios.post('/account/forgot-password', { email, domain: window.location.origin, admin: true }).then(res => {
      notify('please check your email inbox')
      props.history.push('/login')
    })
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}

      className='login_background'
    >
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          height: '500px',
          borderRadius: 10,
          width: '50%',
          background: 'white'
        }}
        className='login_form'
        onSubmit={submit}
      >
        <AccountBoxIcon style={{
          fontSize: '100'
        }}
        />

        <TextField
          id='email'
          name='email'
          label={translate('custom.label.email')}
          variant='outlined'
          style={{
            width: '40%'
          }}
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <Button size='small' type='submit' color='primary'>
          {translate('custom.action.requestPassword')}
        </Button>
        <Link
          style={{
            fontSize: 12
          }}
          to='/login'
        >{translate('ra.action.cancel')}
        </Link>
        <Notification />
      </form>
    </div>
  )
}
export default ForgotPasswordComponent
