// in src/Dashboard.js
import * as React from 'react'
import Card from '@material-ui/core/Card'
import FaceIcon from '@material-ui/icons/Face'
import EditIcon from '@material-ui/icons/Edit'
import LockIcon from '@material-ui/icons/Lock'
import { withRouter } from 'react-router'
import axios from '../../libs/axios'
import Promise from 'bluebird'
import _ from 'lodash'
import CircularProgress from '@material-ui/core/CircularProgress'
import { showNotification, withTranslate } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { connect } from 'react-redux'

class Me extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: true
    }
  }

  componentDidMount () {
    const currentUserString = localStorage.getItem('currentUser')
    let currentUser = false
    if (currentUserString) {
      currentUser = JSON.parse(currentUserString)
    }

    if (_.get(currentUser, '_id', false)) {
      Promise.props({
        getMyProfile: axios.get('/account/profil/' + currentUser._id)
      }).then(res => {
        const me = _.get(res.getMyProfile, 'data', false)
        this.setState({
          isLoading: false,
          me
        })
      })
    }
  }

  render () {
    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          alignItems: 'flex-start',
          padding: '50px 0'
        }}
      >
        {!this.state.isLoading ? (
          <Card
            style={{
              width: '80%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              padding: 20
            }}
          >
            <FaceIcon style={{ fontSize: 100, color: '#535c68' }} />
            <div
              style={{
                display: 'flex',
                width: '80%',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: 20
              }}
            >
              <Typography variant='h5'>
                {this.props.translate('custom.label.civility')}
              </Typography>
              <Typography variant='subtitle2' paragraph>
                {_.get(this.state.me, 'civility', 'undefined')}
              </Typography>

              <Typography variant='h5'>
                {this.props.translate('custom.label.firstName')}
              </Typography>
              <Typography variant='subtitle2' paragraph>
                {_.get(this.state.me, 'firstName', 'undefined')}
              </Typography>

              <Typography variant='h5'>
                {this.props.translate('custom.label.lastName')}
              </Typography>
              <Typography variant='subtitle2' paragraph>
                {_.get(this.state.me, 'lastName', 'undefined')}
              </Typography>

              <Typography variant='h5'>
                {this.props.translate('custom.label.email')}
              </Typography>
              <Typography variant='subtitle2' paragraph>
                {_.get(this.state.me, 'email', 'undefined')}
              </Typography>
              <Typography variant='h5'>
                {this.props.translate('custom.label.profession')}
              </Typography>
              <Typography variant='subtitle2' paragraph>
                {_.get(this.state.me, 'profession', 'undefined')}
              </Typography>

              <Typography variant='h5'>
                {this.props.translate('custom.label.password')}
              </Typography>
              <Typography variant='subtitle2' paragraph>
                <Button
                  variant='outlined'
                  color='primary'
                  startIcon={<LockIcon />}
                  onClick={e => {
                    Promise.props({
                      changePassRequest: axios.post('/account/password', {
                        email: this.state.me.email
                      })
                    }).then(res => {
                      this.props.showNotification(
                        'Password change request email is sent, please check your inbox!'
                      )
                    })
                  }}
                >
                  {this.props.translate('custom.action.changeMyPassword')}
                </Button>
              </Typography>

              <div
                style={{
                  width: '100%'
                }}
              >
                <Button
                  variant='outlined'
                  color='primary'
                  startIcon={<EditIcon />}
                  onClick={e => {
                    this.props.history.push('/users/' + this.state.me.id)
                  }}
                >
                  {this.props.translate('ra.action.edit')}
                </Button>
              </div>
            </div>
          </Card>
        ) : (
          <CircularProgress size={100} color='secondary' />
        )}
      </div>
    )
  }
}

export default connect(undefined, { showNotification })(
  withTranslate(withRouter(Me))
)
