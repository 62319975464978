import React from 'react'
import './App.css'
import jsonServerProvider from './libs/serverProvider'
import {Admin, Resource, fetchUtils} from 'react-admin'
import {UserCreate} from './component/users/UserCreate'
import {Dashboard} from './component/page/Dashboard'
import {UserEdit} from './component/users/UserEdit'
import {UserShow} from './component/users/UserShow'
import {UserList} from './component/users/UserList'
import {MetaDataList} from './component/metadatas/MetaDataList'
import authProvider from './libs/authProvider'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import customFrenchMessages from './i18n/fr'
import _ from 'lodash'
import customEnglishMessages from './i18n/en'
import UserIcon from '@material-ui/icons/People'
import NoteIcon from '@material-ui/icons/Note'
import SchoolIcon from '@material-ui/icons/School'
import EventIcon from '@material-ui/icons/Event'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import NewReleasesIcon from '@material-ui/icons/NewReleases'
import {MetaDataCreate} from './component/metadatas/MetaDataCreate'
import {MetaDataEdit} from './component/metadatas/MetaDataEdit'
import {MetaDataShow} from './component/metadatas/MetaDataShow'
import {GuideCreate} from './component/guides/GuideCreate'
import {GuideEdit} from './component/guides/GuideEdit'
import {GuideShow} from './component/guides/GuideShow'
import {GuideList} from './component/guides/GuideList'
import DescriptionIcon from '@material-ui/icons/Description'
import SettingsIcon from '@material-ui/icons/Settings'
import WcIcon from '@material-ui/icons/Wc'
import FormationCreate from './component/formation/FormationCreate'
import {FormationEdit} from './component/formation/FormationEdit'
import {FormationShow} from './component/formation/FormationShow'
import {FormationList} from './component/formation/FormationList'
import AccompanimentCreate from './component/accompaniment/AccompanimentCreate'
import {AccompanimentEdit} from './component/accompaniment/AccompanimentEdit'
import {AccompanimentShow} from './component/accompaniment/AccompanimentShow'
import {AccompanimentList} from './component/accompaniment/AccompanimentList'
import {EventCreate} from './component/event/EventCreate'
import {EventEdit} from './component/event/EventEdit'
import {EventShow} from './component/event/EventShow'
import {EventList} from './component/event/EventList'
import {NewsCreate} from './component/news/NewsCreate'
import {NewsEdit} from './component/news/NewsEdit'
import {NewsShow} from './component/news/NewsShow'
import {NewsList} from './component/news/NewsList'
import {CommitteeCreate} from './component/committee/CommitteeCreate'
import {CommitteeEdit} from './component/committee/CommitteeEdit'
import {CommitteeShow} from './component/committee/CommitteeShow'
import {CommitteeList} from './component/committee/CommitteeList'
import LoginComponent from './component/authentication/login'
import {customRoutes} from './libs/customRoutes'
import {StaticPageCreate} from './component/staticPages/StaticPageCreate'
import {StaticPageEdit} from './component/staticPages/StaticPageEdit'
import {StaticPageShow} from './component/staticPages/StaticPageShow'
import {StaticPageList} from './component/staticPages/StaticPageList'
import {SettingsEdit} from "./component/settings/SettingsEdit";

const messages = {
    fr: customFrenchMessages,
    en: customEnglishMessages
}
const i18nProvider = polyglotI18nProvider(locale => messages.fr, {
    allowMissing: true
})

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'})
    }
    const xApiKey = localStorage.getItem('xApiKey')
    const xRefreshToken = localStorage.getItem('xRefreshToken')
    options.headers.set('x-api-key', `${xApiKey}`)
    options.headers.set('x-refresh-token', `${xRefreshToken}`)
    return fetchUtils.fetchJson(url, options)
}

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dataProvider: jsonServerProvider(
                _.get(process.env, 'REACT_APP_BACK_URL', 'https://api.prodiadom.devel'),
                httpClient
            )
        }
    }

    render() {
        return (
            <div className='App'>
                <Admin
                    loginPage={LoginComponent}
                    customRoutes={customRoutes}
                    i18nProvider={i18nProvider}
                    dataProvider={this.state.dataProvider}
                    authProvider={authProvider}
                    dashboard={Dashboard}
                >
                    {permissions => [
                        // permissions === 'root' ? (
                        //     <Resource
                        //         name='users'
                        //         create={UserCreate}
                        //         edit={UserEdit}
                        //         show={UserShow}
                        //         list={UserList}
                        //         icon={UserIcon}
                        //     />
                        // ) : null,
                            <Resource
                                name='users'
                                create={UserCreate}
                                edit={UserEdit}
                                show={UserShow}
                                list={UserList}
                                icon={UserIcon}
                            />,
                        <Resource
                            name='metadatas'
                            create={MetaDataCreate}
                            edit={MetaDataEdit}
                            show={MetaDataShow}
                            list={MetaDataList}
                            icon={NoteIcon}
                        />,
                        <Resource
                            name='guides'
                            create={GuideCreate}
                            edit={GuideEdit}
                            show={GuideShow}
                            list={GuideList}
                            icon={MenuBookIcon}
                        />,
                        <Resource
                            name='formations'
                            create={FormationCreate}
                            edit={FormationEdit}
                            show={FormationShow}
                            list={FormationList}
                            icon={SchoolIcon}
                        />,
                        <Resource
                            name='accompaniments'
                            create={AccompanimentCreate}
                            edit={AccompanimentEdit}
                            show={AccompanimentShow}
                            list={AccompanimentList}
                            icon={SupervisedUserCircleIcon}
                        />,
                        <Resource
                            name='events'
                            create={EventCreate}
                            edit={EventEdit}
                            show={EventShow}
                            list={EventList}
                            icon={EventIcon}
                        />,

                        <Resource
                            name='news'
                            create={NewsCreate}
                            edit={NewsEdit}
                            show={NewsShow}
                            list={NewsList}
                            icon={NewReleasesIcon}
                        />,

                        <Resource
                            name='committee'
                            create={CommitteeCreate}
                            edit={CommitteeEdit}
                            show={CommitteeShow}
                            list={CommitteeList}
                            icon={WcIcon}
                        />,
                        <Resource
                            name='staticPages'
                            create={permissions === 'root' ? StaticPageCreate : null}
                            edit={StaticPageEdit}
                            show={StaticPageShow}
                            list={StaticPageList}
                            icon={DescriptionIcon}
                        />
                    ]}
                </Admin>
            </div>
        )
    }
}

export default App
