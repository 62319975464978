import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  useTranslate
} from 'react-admin'
import { useFormState } from 'react-final-form'

export const MetaDataCreate = (props) => {
  const OnField = props => {
    const { values } = useFormState()
    return values.onModel === 'user'
      ? (
        <SelectInput
          source='onField'
          optionText='name'
          choices={[
            { id: 'profession', name: 'Profession' }
          ]}
          {...props}
        />
      ) : null
  }

  const translate = useTranslate()

  return (
    <Create {...props}>
      <SimpleForm>
        <div className='form_field_container'>
          <div className='field_container'>
            <TextInput label={translate('resources.metadatas.fields.name')} source='name' />
            <SelectInput
              source='onModel'
              label={translate('resources.metadatas.fields.onModel')}
              optionText='name'
              choices={[
                { id: 'accompaniment', name: 'Accompagnement' },
                { id: 'formation', name: 'Formation' },
                { id: 'guide', name: 'Guide' },
                { id: 'user', name: 'Utilisateur' }
              ]}
            />
            <OnField {...props} />
          </div>
        </div>
      </SimpleForm>
    </Create>
  )
}
