import * as React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  TextInput,
  DateField,
  DeleteButton,
  ShowButton,
  Filter, useTranslate
} from 'react-admin'

const NewsFilter = (props) => (
  <Filter {...props}>
    <TextInput source='search' alwaysOn />
  </Filter>
)

export const NewsList = (props) => {
  const translate = useTranslate()
  return (
    <List title={translate('pos.dashboard.news')} {...props} filters={<NewsFilter />}>
      <Datagrid>
        <TextField source='title' />
        <DateField source='createAt' />
        <ShowButton basePath='/news' />
        <EditButton basePath='/news' />
        <DeleteButton basePath='/news' />
      </Datagrid>
    </List>
  )
}
