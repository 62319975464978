import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  minLength,
  maxLength,
  regex,
  email,
  choices,
  useTranslate,
  ReferenceInput, BooleanInput
} from 'react-admin'

const UserTitle = ({ record }) => {
  return <span>Modifier l'utilisateur {record ? `"${record.email}"` : ''}</span>
}

export const UserEdit = props => {
  const message = {
    required: 'This field is required',
    name: 'Must be enter 2 and 15 characters',
    rppsNumber: 'Must be 11 characters'
  }
  const validateFirstName = [
    required(message.required),
    minLength(2, message.name),
    maxLength(15, message.name)
  ]
  const validateLastName = [
    required(message.required),
    minLength(2, message.name),
    maxLength(15, message.name)
  ]
  const validateRppsNumber = [
    required(message.required),
    minLength(11, message.rppsNumber),
    maxLength(11, message.rppsNumber)
  ]
  const validateEmail = [required(message.required), email()]
  const validateZipCode = regex(/^\d{5}$/, 'Must be a valid Zip Code')
  const validateCivility = [
    required(message.required),
    choices(['M', 'Mme'], 'Must be M or Mme')
  ]
  const validateRole = [
    required(message.required),
    choices(['root', 'admin', 'user'], 'Must be User, Admin or Root')
  ]
  const translate = useTranslate()
  return (
    <Edit title={<UserTitle />} {...props}>
      <SimpleForm>
        <div className='form_field_container'>
          <div className='field_container'>
            <SelectInput
              label={translate('resources.users.fields.civility')}
              source='civility'
              validate={validateCivility}
              optionText='name'
              choices={[
                { id: 'M', name: 'M' },
                { id: 'Mme', name: 'Mme' }
              ]}
            />
            <TextInput
              label={translate('resources.users.fields.firstName')}
              source='firstName'
              validate={validateFirstName}
            />
            <TextInput
              label={translate('resources.users.fields.lastName')}
              source='lastName'
              validate={validateLastName}
            />
            <TextInput
              label={translate('resources.users.fields.email')}
              source='email'
              type='email'
              validate={validateEmail}
            />
            <SelectInput
              label={translate('resources.users.fields.role')}
              source='role'
              validate={validateRole}
              optionText='name'
              choices={[
                { id: 'root', name: 'Root' },
                { id: 'admin', name: 'Admin' },
                { id: 'user', name: 'User' }
              ]}
            />

            <TextInput
              label={translate('resources.users.fields.rppsNumber')}
              source='rppsNumber'
              validate={validateRppsNumber}
            />
            <ReferenceInput
              label={translate(
                'resources.users.fields.profession'
              )}
              source='profession'
              reference='metadatas'
              filterToQuery={q => {
                return {
                  onModel: 'user',
                  onField: 'profession'
                }
              }}
              link={false}
            >
              <SelectInput optionText='name' />
            </ReferenceInput>
            <SelectInput
              label={translate('resources.users.fields.exerciceMode')}
              source='exerciceMode'
              optionText='name'
              validate={required(message.required)}
              choices={[
                { id: 'careEstablishment', name: 'Etablissement de soin' },
                { id: 'liberalExercise', name: 'Exercice libéral' },
                { id: 'others', name: 'Autres' }
              ]}
            />

            <TextInput
              label={translate('resources.users.fields.establishment')}
              source='establishment'
            />

            <TextInput
              label={translate('resources.users.fields.businessAddress')}
              source='businessAddress'
            />
            <TextInput
              label={translate('resources.users.fields.zipcode')}
              source='zipcode'
              validate={validateZipCode}
            />
            <TextInput
              label={translate('resources.users.fields.city')}
              source='city'
            />
            <BooleanInput
                label={translate('resources.users.fields.receiveNewsletter')}
                source='receiveNewsletter'
            />
          </div>
        </div>
      </SimpleForm>
    </Edit>
  )
}
