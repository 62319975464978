import React from 'react'
import { WithPermissions, NotFound } from 'react-admin'
import Me from './Me'

const MeComponent = ({ location, match }) => (
  <WithPermissions
    authParams={{ key: match.path, params: {} }}
    location={location}
    render={({ permissions }) => permissions === 'root' || permissions === 'admin'
      ? <Me permissions={permissions} /> : <NotFound />}
  />
)

export default MeComponent
