const FormField = ({style = {}, children, id, label}) => {
    return (
        <div style={{...style}}>
            <div style={{
                margin: '5px 0'
            }}>
                {label && <label htmlFor={id}>{label}</label>}
            </div>
            <div style={{
                margin: '5px 0',
                width: '50%',
                display: 'flex',
                alignItems: 'flex-start'
            }}>
                <div style={{
                    width: '100%'
                }}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default FormField
