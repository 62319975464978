import React, { useState } from 'react'
import axios from '../../libs/axios'
import { useNotify, Notification, useTranslate } from 'react-admin'
import { Link } from 'react-router-dom'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

const ResetPassword = props => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const notify = useNotify()
  const translate = useTranslate()
  const query = new URLSearchParams(props.location.search)
  const token = query.get('token')
  const id = query.get('id')

  const submit = e => {
    e.preventDefault()

    if (
      password.length > 0 &&
      confirmPassword.length > 0 &&
      password === confirmPassword
    ) {
      axios
        .put('/account/reset-password', {
          password,
          token,
          id
        })
        .then(() => {
          setPassword('')
          setConfirmPassword('')
          notify('Password modified successfully')
          props.history.push('/')
        })
        .catch(err => {
          setPassword('')
          setConfirmPassword('')
          notify('An error occured')
        })
    } else {
      notify('passwords are different')
    }
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      className='login_background'
    >
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          height: '500px',
          borderRadius: 10,
          width: '50%',
          background: 'white'
        }}
        className='login_form'
        onSubmit={submit}
      >
        <AccountBoxIcon
          style={{
            fontSize: '100'
          }}
        />

        <TextField
          id='password'
          name='password'
          label={translate('custom.label.password')}
          variant='outlined'
          style={{
            width: '40%'
          }}
          value={password}
          onChange={e => setPassword(e.target.value)}
        />

        <TextField
          id='confirmPassword'
          name='confirmPassword'
          label={translate('custom.label.confirmPassword')}
          variant='outlined'
          style={{
            width: '40%'
          }}
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
        />
        <Button size='small' type='submit' color='primary'>
          {translate('ra.action.save')}
        </Button>
        <Link
          style={{
            fontSize: 12
          }}
          to='/login'
        >
          {translate('ra.action.cancel')}
        </Link>
        <Notification />
      </form>
    </div>
  )
}

export default ResetPassword
