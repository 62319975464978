import * as React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  useTranslate
} from 'react-admin'
import { useFormState } from 'react-final-form'

const MetaTitle = ({ record }) => {
  return <span>Modifier la méta-donnée {record ? `"${record.name}"` : ''}</span>
}

export const MetaDataEdit = (props) => {
  const OnField = props => {
    const { values } = useFormState()
    return values.onModel === 'user'
      ? (
        <SelectInput
          source='onField'
          optionText='name'
          choices={[
            { id: 'profession', name: 'Profession' }
          ]}
          {...props}
        />
      ) : null
  }

  const translate = useTranslate()
  return (
    <Edit title={<MetaTitle />} {...props}>
      <SimpleForm redirect='show'>
        <div className='form_field_container'>
          <div className='field_container'>
            <TextInput label={translate('resources.metadatas.fields.name')} source='name' />
            <SelectInput
              label={translate('resources.metadatas.fields.onModel')}
              source='onModel'
              optionText='name'
              choices={[
                { id: 'accompaniment', name: 'Accompagnement' },
                { id: 'formation', name: 'Formation' },
                { id: 'guide', name: 'Guide' },
                { id: 'user', name: 'Utilisateur' }
              ]}
            />
            <OnField {...props} />
          </div>
        </div>
      </SimpleForm>
    </Edit>
  )
}
