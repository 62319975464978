import FormField from './FormField'
import _ from 'lodash'
import { Controller } from 'react-hook-form'
import Switch from '@material-ui/core/Switch'

const BooleanInput = ({ register, field, label, defaultValue, control, style= {} }) => {
  return (
    <FormField id={field} label={label} style={{...style}}>
      <Controller
        control={control}
        name={field}
        defaultValue={defaultValue}
        render={(
          { onChange, onBlur, value, name, ref }
        ) => (
          <Switch
            id={field}
            type='checkbox'
            name={name}
            onBlur={onBlur}
            onChange={(e) => onChange(e.target.checked)}
            checked={value}
            inputRef={ref}
          />
        )}
      />
    </FormField>
  )
}

export default BooleanInput
