import * as React from 'react'
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    ImageInput,
    ImageField,
    FileInput,
    FileField
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'

const StaticPageTitle = ({record}) => {
    return <span>Modifier la page {record ? `"${record.name}"` : ''}</span>
}

export const StaticPageEdit = props => {
    const translate = useTranslate()

    return (
        <Edit title={<StaticPageTitle/>} {...props}>
            <SimpleForm redirect='show'>
                <div
                    className='form_field_container'
                    style={{
                        flexDirection: 'column'
                    }}
                >
                    <div className='field_container'>
                        <ImageInput
                            headerClassName={'testa'}
                            label={translate('resources.news.fields.mainImage')}
                            source='mainImage'
                            accept={['.jpg', '.jpeg', '.JPG', '.png', '.gif']}
                            labelSingle={translate('resources.news.fields.uploadImage')}
                            maxSize={5000000}
                            children={<ImageField source='src'/>}
                        />

                        {
                            props.permissions === 'root' && (
                                <TextInput
                                    label={translate('resources.staticPages.fields.key')}
                                    source='key'
                                />
                            )
                        }

                        <TextInput
                            label={translate('resources.staticPages.fields.name')}
                            source='name'
                        />
                    </div>
                    <RichTextInput
                        label={translate('resources.staticPages.fields.content')}
                        source='content'
                        toolbar={[
                            ['bold', 'italic', 'underline'], // toggled buttons
                            ['link', 'image', 'video'],
                            [{list: 'bullet'}],
                            [{script: 'sub'}, {script: 'super'}], // superscript/subscript
                            [{header: [2, 3, false]}],
                            ['clean'] // remove formatting button
                        ]}
                    />


                    <div className='field_container'>
                        <FileInput multiple={true} source="attachedPdfs"label={translate('resources.staticPages.fields.attachedPdfs')} accept="application/pdf"
                                   placeholder={<p>Ajouter un/des PDF(s)</p>}>
                            <FileField source="src" title="title"/>
                        </FileInput>
                    </div>


                </div>
            </SimpleForm>
        </Edit>
    )
}
