import * as React from 'react'
import {
    Create,
    SimpleForm,
    TextInput,
    ImageInput,
    ImageField,
    useTranslate, FileInput, FileField
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'

export const StaticPageCreate = props => {
    const translate = useTranslate()

    return (
        <Create {...props}>
            <SimpleForm enctype='multipart/form-data'>
                <div
                    className='form_field_container'
                    style={{
                        flexDirection: 'column'
                    }}
                >
                    <div className='field_container'>
                        <ImageInput
                            label={translate('resources.news.fields.mainImage')}
                            source='mainImage'
                            accept={['.jpg', '.jpeg', '.JPG', '.png', '.gif']}
                            labelSingle={translate('resources.news.fields.uploadImage')}
                            maxSize={5000000}
                            children={<ImageField/>}
                        />
                        <TextInput
                            label={translate('resources.staticPages.fields.key')}
                            source='key'
                        />
                        <TextInput
                            label={translate('resources.staticPages.fields.name')}
                            source='name'
                        />
                    </div>
                    <RichTextInput
                        label={translate('resources.staticPages.fields.content')}
                        source='content'
                        toolbar={[
                            ['bold', 'italic', 'underline'], // toggled buttons
                            ['link', 'image', 'video'],
                            [{list: 'bullet'}],
                            [{script: 'sub'}, {script: 'super'}], // superscript/subscript
                            [{header: [2, 3, false]}],
                            ['clean'] // remove formatting button
                        ]}
                    />

                    <div className='field_container'>
                        <FileInput multiple={true} source="attachedPdfs"
                                   label={translate('resources.staticPages.fields.attachedPdfs')}
                                   accept="application/pdf"
                                   placeholder={<p>Ajouter un/des PDF(s)</p>}>
                            <FileField source="src" title="title"/>
                        </FileInput>
                    </div>
                </div>
            </SimpleForm>
        </Create>
    )
}
