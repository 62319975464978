import * as React from 'react'
import { Show, ArrayField, Datagrid, SimpleShowLayout, ReferenceField, NumberField, DateField } from 'react-admin'
import SimpleItemField from '../fields/SimpleItemField'
import TextField from '../fields/TextField'

const AccompanimentTitle = ({ record }) => {
  return <span>Accompaniment {record ? `"${record.name}"` : ''}</span>
}

export const AccompanimentShow = (props) => (
  <Show title={<AccompanimentTitle />} {...props}>
    <SimpleShowLayout className='show_field_container'>
      <TextField label='resources.formations.fields.title' source='title' />
      <TextField label='resources.formations.fields.keyGa' source='keyGa'  />
      <ReferenceField
        label='resources.accompaniments.fields.accompanimentType'
        link='show'
        className='referenceField'
        source='accompanimentType' reference='metadatas'
      >
        <TextField source='name' noMarginBottom />
      </ReferenceField>
      <TextField label='resources.formations.fields.public' source='public' />
      <TextField label='resources.formations.fields.description' source='description' />
      <TextField label='resources.formations.fields.prerequisite' source='prerequisite' />
    </SimpleShowLayout>
  </Show>
)

/*

            <RichTextField source="body" />
            <DateField label="Publication date" source="created_at" />
 */
