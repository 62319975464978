import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { Edit, useNotify } from 'react-admin'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import Select from 'react-select'
import translater from '../../i18n/fr'
import axios from '../../libs/axios'
import serverProvider from '../../libs/serverProvider'
import ArrowInput from './ArrowInput'
import BlockInput from './BlockInput'
import BooleanInput from './BooleanInput'
import FormError from './FormError'
import FormField from './FormField'
import FormInput from './FormInput'
import httpClient from "./httpClient"


const Toto = ({record}) => {
    const notify = useNotify()

    /** SETSTATE */
    const [defaultGuideType, setDefaultGuideType] = useState(null)
    const [guideType, setGuideType] = useState([])
    const [guide, setGuide] = useState(record)
    const [formationsOptions, setFormationsOptions] = useState([])
    let watcher = []
    let [isLoading, setIsLoading] = useState(false)

    /** USEFORM */
    const {register, handleSubmit, errors, control, watch} = useForm({
        defaultValues: guide
    })


    const {fields, append, remove, move} = useFieldArray({
        control,
        name: 'level'
    })


    /** USEEFFECT */
    useEffect(() => {
        const fetchDatas = async () => {
            const metadatas = await axios.get('/metadatas?onModel=guide')
            const formations = await axios.get('/formations')
            setFormationsOptions(formations.data.map(d => { return { _id: d._id, name: d.title }}))
            setGuideType(metadatas.data)
            setDefaultGuideType(_.get(_.filter(metadatas.data, type => type._id === record.guideType), [0], []))
        }
        fetchDatas()
    }, [])

    /** ONSUBMIT */
    const onSubmit = (values) => {
        setIsLoading(true)
        _.map(values.level, (lvl, indexLvl) => {
            _.map(lvl.blocks, (block, indexBlock) => {
                // console.log('---- DL ----')
                let tmpFiles = [];
                _.get(values, 'level[' + indexLvl + '].blocks[' + indexBlock + '].download', []).forEach((file, index) => {
                    _.get(guide, 'level[' + indexLvl + '].blocks[' + indexBlock + '].download', []).forEach((existedFile, jIndex) => {
                        if (file._id && file._id === existedFile._id) {
                            tmpFiles.push(existedFile)
                        }
                    })
                })
                _.set(values, `level[${indexLvl}].blocks[${indexBlock}].download`, _.union(tmpFiles, _.get(values, 'level[' + indexLvl + '].blocks[' + indexBlock + '].currentDownload', [])))
            })
        })
        serverProvider(process.env.REACT_APP_BACK_URL, httpClient).update('guides', {id: record?.id, data: values})
            .then(resp => {
                setIsLoading(false)
                notify('Enregistré avec succès !')
            })
            .catch(err => console.log(err))
    }

    /** OPTIONS */
    const options = {
        guideType,
        elementPosition: [
            {_id: 1, name: 'Un élément central'},
            {_id: 2, name: 'Un élément a gauche'},
            {_id: 3, name: 'Un élément a droite'},
            {_id: 4, name: 'Deux éléments'}
        ]
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return
        }
        move(result.source.index, result.destination.index)
    }

    if (!guideType || !defaultGuideType) {
        return 'loading...'
    }

    //console.log('render: ', guide)
    console.log('errors: ', errors)


    const getNbLevels = () => {
        let nbLevels = 0;
        fields.forEach(item => {
            if (item) {
                nbLevels++;
            }
        })
        return nbLevels
    }


    const styles = {
        formField: {
            width: '50%',
            margin: '10px 0',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
        booleanInput: {
            display: 'flex',
            width: '100%',
            margin: '10px 0',
            alignItems: 'center'
        }
    }

    return (
        <div style={{
            position: 'relative',
            padding: 20
        }}>
            <DragDropContext
                onDragEnd={onDragEnd}
            >

                <h1>Modifier le guide </h1>

                <div style={{
                    width: '100%',
                    display: 'flex'
                }}>

                <form onSubmit={handleSubmit(onSubmit)} style={{
                    padding: 20,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'space-between',
                    width: '60%',
                    border: '.5px solid blue'
                }}
                >

                    <FormField id='name' label='Nom du guide' style={styles.formField}>
                        <FormInput
                            name='name'
                            type='text'
                            defaultValue={guide.name}
                            rules={{
                                minLength: {
                                    value: 2,
                                    message: 'Doit contenir au moins deux caracteres'
                                },
                                required: {
                                    value: true,
                                    message: 'Ce champ est requis'
                                }
                            }}
                            control={control}
                        />
                        <FormError error={errors.name}/>
                    </FormField>



                    <FormField id='keyGa' label={'Clé google analytics'} style={styles.formField}>
                        <FormInput
                            name='keyGa'
                            type='text'
                            defaultValue={guide.keyGa}
                            rules={{
                                required: {
                                    value: false,
                                }
                            }}
                            control={control}
                        />
                        <FormError error={errors.keyGa}/>
                    </FormField>

                    <FormField id='guideType' label='Type de guide' style={styles.formField}>
                        <Controller
                            control={control}
                            defaultValue={defaultGuideType._id}
                            name='guideType'
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Ce champ est requis'
                                }
                            }}
                            render={(
                                {onChange, ref}
                            ) => (
                                <Select
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => option._id}
                                    options={options.guideType}
                                    defaultValue={defaultGuideType}
                                    onChange={selected => onChange(selected._id)}
                                    inputRef={ref}
                                />
                            )}
                        />
                        <FormError error={errors.guideType}/>
                    </FormField>

                    <BooleanInput
                        style={styles.booleanInput}
                        label="Afficher"
                        field={`display`}
                        toChange={`display`}
                        register={register}
                        control={control}
                        defaultValue={_.get(guide, 'display', false)}
                    />

                    <div style={{
                        padding: 5,
                        textAlign: 'left',
                        borderBottom: '.5px solid grey'
                    }}>
                        <h2>Levels</h2>
                    </div>

                    <Droppable droppableId='droppable'>
                        {(provided, snapshot) => (
                            <div
                                style={{
                                    minHeight: 200,
                                }}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {_.map(fields, (item, index) => {
                                    // Watcher for show or hidden arrows blocks
                                    watcher = [...watcher, ...[{
                                        booleanStandardLine: watch(`level[${index}].booleanStandardLine`),
                                        booleanReturnLine: watch(`level[${index}].booleanReturnLine`),
                                    }]]
                                    if (item?.id) {
                                        return (
                                            <Draggable key={item?.id} draggableId={item?.id} index={index}>
                                                {(provided, snapshot) => (

                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <Accordion>
                                                            <div style={{
                                                                display: 'flex',
                                                                marginTop: 20,
                                                                marginBottom: 20,
                                                            }}>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon/>}
                                                                    aria-controls='panel1a-content'
                                                                    id='panel1a-header'
                                                                    style={{flex: '1 1 100%'}}
                                                                >
                                                                    <div style={{
                                                                        borderBottom: '.5px solid grey'
                                                                    }}>
                                                                        <h3> Level {(index + 1)}</h3>
                                                                    </div>
                                                                </AccordionSummary>
                                                                <Button onClick={() => remove(index)} color='primary'>
                                                                    <DeleteIcon/>
                                                                </Button>

                                                            </div>
                                                            <AccordionDetails>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    alignItems: 'flex-start',
                                                                    width: '100%',
                                                                }}>
                                                                    <FormField id={`level[${index}].elementPositionId`}
                                                                               label="Position de l'element"
                                                                               style={styles.formField}>
                                                                        <Controller
                                                                            control={control}
                                                                            name={`level[${index}].elementPositionId`}
                                                                            defaultValue={item?.elementPositionId}
                                                                            rules={{
                                                                                validate: value =>
                                                                                    (value > 0 && value < 5) || 'Doit etre entre 1 et 4',
                                                                                required: {
                                                                                    value: true,
                                                                                    message: 'Ce champ est requis'
                                                                                }
                                                                            }}
                                                                            render={(
                                                                                {onChange, ref}
                                                                            ) => (
                                                                                <Select
                                                                                    getOptionLabel={option => option.name}
                                                                                    getOptionValue={option => option._id}
                                                                                    options={options.elementPosition}
                                                                                    defaultValue={_.filter(options.elementPosition, element => element._id === item.elementPositionId)}
                                                                                    onChange={selected => onChange(selected._id)}
                                                                                    inputRef={ref}
                                                                                />
                                                                            )}
                                                                        />
                                                                        <FormError
                                                                            error={_.get(errors, ['level', index, 'elementPositionId'])}/>
                                                                    </FormField>

                                                                    <BooleanInput
                                                                        style={styles.booleanInput}
                                                                        label='Ajouter une ligne standard'
                                                                        field={`level[${index}].booleanStandardLine`}
                                                                        toChange={`level[${index}].booleanStandardLine]`}
                                                                        register={register}
                                                                        control={control}
                                                                        defaultValue={item?.booleanStandardLine}
                                                                    />


                                                                    {
                                                                        (_.get(watcher, [index, 'booleanStandardLine'], 'false') === 'true' || _.get(watcher, [index, 'booleanStandardLine'], false) === true) && (
                                                                            <ArrowInput
                                                                                control={control}
                                                                                indexLevel={index}
                                                                                defaultLine='standardLines'
                                                                                Controller={Controller}
                                                                                errors={errors}
                                                                            />
                                                                        )
                                                                    }

                                                                    <BooleanInput
                                                                        style={styles.booleanInput}
                                                                        label='Ajouter une ligne de retour'
                                                                        field={`level[${index}].booleanReturnLine`}
                                                                        register={register}
                                                                        control={control}
                                                                        defaultValue={item?.booleanReturnLine}
                                                                    />
                                                                    {
                                                                        (_.get(watcher, [index, 'booleanReturnLine'], 'false') === 'true' || _.get(watcher, [index, 'booleanReturnLine'], false) === true) && (
                                                                            <ArrowInput
                                                                                control={control}
                                                                                indexLevel={index}
                                                                                defaultLine='returnLines'
                                                                                Controller={Controller}
                                                                                errors={errors}
                                                                            />
                                                                        )
                                                                    }

                                                                    <div style={{
                                                                        margin: '20px 0',
                                                                        width: '100%',
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignSelf: 'center'
                                                                    }}>
                                                                        <BlockInput
                                                                            nbLevels={getNbLevels()}
                                                                            indexLevel={index}
                                                                            control={control}
                                                                            errors={errors}
                                                                            register={register}
                                                                            watch={watch}
                                                                            guide={guide}
                                                                            setGuide={setGuide}
                                                                            formationsOptions={formationsOptions}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </div>
                                                )}
                                            </Draggable>
                                        )
                                    } else {
                                        return <></>
                                    }
                                })}
                            </div>
                        )}
                    </Droppable>

                    <div style={{
                        display: 'flex',
                        alignSelf: 'center',
                        alignItems: 'center',
                        width: 300,
                        margin: '25px 0',
                        justifyContent: 'space-evenly'
                    }}
                    >
                        <Button
                            color='secondary'
                            variant='contained'
                            onClick={() => {
                                append({
                                    blocks: [],
                                    booleanLastLine: false,
                                    booleanPrincipalReturnLine: false,
                                    booleanReturnLine: false,
                                    booleanStandardLine: false,
                                    elementPositionId: 0,
                                    lastLines: [],
                                    principalReturnLines: [],
                                    returnLines: [],
                                    standardLines: []
                                })
                            }}
                        >
                            <AddIcon/>
                        </Button>
                        <span>Ajouter un nouveau level</span>
                    </div>


                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%'
                    }}>
                        <Button type='submit'>
                            Sauvegarder
                        </Button>
                    </div>

                    <Debbuger errors={errors}/>
                </form>
                    <div style={{
                        width: '40%'
                    }}>
                        {
                            !isLoading ? (<Iframe id={guide._id} />) : (
                                <div style={{
                                display: 'flex',
                                justifyContent:'center',
                                alignItems: 'center'
                            }}><span>loading ...</span></div>
                            )
                        }
                    </div>
                </div>
            </DragDropContext>
        </div>
    )
}

const Iframe = ({id}) => {
    return (
            <iframe
                style={{
                    height: '100%',
                    width: '100%',
                    top: '130px',
                    right: '20px'
                }}
                title='Previsualisation'
                src={
                    _.get(process.env, 'REACT_APP_WEBAPP_URL') +
                    '/guides/' +
                    id +
                    '?header=0&footer=0'
                }
            />
    )
}

const Debbuger = ({ errors }) => {
    let tmp = []
    getErrorsInArray(errors, tmp)
    if (tmp.length > 0) {
        return (
                <div>
                    <h2 style={{margin: '20px auto 5px'}}>Liste des erreurs</h2>
                    <table style={{width: '100%'}}>
                        <thead>
                            <tr style={{textAlign: 'left'}}>
                                <th>
                                    Nom
                                </th>
                                <th>
                                    Message
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                _.map(tmp, (error, index) => {
                                    let name = error.name
                                    name = name.replace(/\[/g, '.')
                                    name = name.replace(/\]/g, '.')
                                    name = name.split('.')
                                    name = name.map(value => {
                                        if (value.match(/[\d]+/)) {
                                            value = parseInt(value) + 1
                                        }
                                        const translateName = translater.resources.guides.fields[value]
                                        if (translateName === undefined) {
                                            return value
                                        }
                                        return translateName
                                    })
                                    name = name.join(' ')
                                    return (
                                        <tr style={index % 2 === 0 ? {backgroundColor: '#ecf0f1',textAlign: 'left' } : { backgroundColor: '#bdc3c7',textAlign: 'left'}}>
                                            <td style={{color: 'red', padding: '10px', width: '60%'}}>{name}</td>
                                            <td style={{color: 'red', padding: '10px', width: '40%'}}>{error.message}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            )
    }
    return ''
}

function getErrorsInArray(errors, tmp) {
    _.forOwn(errors, (error) => {
        if (error?.ref) {
            tmp.push({name : error?.ref?.name, message: error.message})
        } else {
            getErrorsInArray(error, tmp)
        }
    })
    return tmp
}

export const GuideEdit = props => {

    return (
        <div>
            <Edit children={<Toto/>} {...props} />
        </div>
    )
}
