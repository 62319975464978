import FormField from './FormField'
import FormError from './FormError'
import FormInput from './FormInput'
import BooleanInput from './BooleanInput'
import Select from 'react-select'
import _ from 'lodash'
import {useFieldArray, Controller} from 'react-hook-form'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'

const Downloads = ({indexLevel, indexBlock, control}) => {
    const {fields, remove} = useFieldArray({
        control,
        name: `level[${indexLevel}].blocks[${indexBlock}].download`
    })

    return _.map(fields, (file, indexDl) => {
        return (
            <div style={{
                width: '30%',
                display: 'flex',
                justifyContent: 'space-between',
                alignSelf: 'center',
                alignItems: 'center',
                margin: 2,
            }}
                 key={file?.id + '' + indexDl}>
                <FormField id={`level[${indexLevel}].blocks[${indexBlock}].download[${indexDl}]._id`}
                           label={file.title}>
                    <FormInput
                        style={{
                            visibility: 'hidden'
                        }}
                        disabled={true}
                        name={`level[${indexLevel}].blocks[${indexBlock}].download[${indexDl}]._id`}
                        type='text'
                        control={control}
                        defaultValue={_.get(file, '_id', '')}
                    />
                </FormField>
                <Button
                    onClick={() => {
                        remove(indexDl)
                    }} color='primary'
                >
                    <DeleteIcon/>
                </Button>
            </div>
        )
    })
}

const BlockInput = ({indexLevel, control, errors, register, watch, guide, setGuide, nbLevels, formationsOptions}) => {
    const {fields, append, remove} = useFieldArray({
        control,
        name: `level[${indexLevel}].blocks`
    })

    let targetOptions = [];
    for (let i = 0; i < nbLevels; i++) {
        if (i !== indexLevel) {
            targetOptions.push({_id: i, name: i + 1})
        }
    }


    const typeOptions= [
        {_id: 'positive' , name : 'Positif'},
        {_id: 'negative' , name : 'Négatif'},
        {_id: 'standard' , name : 'Standard'},
    ]
    const watcher = []
    const options = {
        blockLevel: [
            {_id: 1, name: 'Element de niveau 1'},
            {_id: 2, name: 'Element de niveau 2'},
            {_id: 3, name: 'Element de niveau 3'},
            {_id: 4, name: 'Element de niveau 4'},
            {_id: 5, name: 'Debut de la dialyse'},
            {_id: 6, name: 'Guide suivant'},
            {_id: 7, name: 'Ancre'},
            {_id: 8, name: 'Lien externe'},
        ]
    }

    const handleControlledDropzonChangeStatus = (status, allFiles, setFiles) => {
        setTimeout(() => {
            if (['done', 'removed'].includes(status)) {
                setFiles([...allFiles])
            }
        }, 0)
    }

    const styles = {
        formField: {
            width: '50%',
            margin: '10px 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        booleanInput: {
            display: 'flex',
            width: '100%',
            margin: '10px 0',
            alignItems: 'center'
        }
    }

    return (
        <div style={{
            width: '90%'
        }}>
            {_.map(fields, (item, index) => {
                watcher.push({
                    booleanConsensus: watch(`level[${indexLevel}].blocks[${index}].booleanConsensus`),
                    booleanDownload: watch(`level[${indexLevel}].blocks[${index}].booleanDownload`),
                    download: watch(`level[${indexLevel}].blocks[${index}].download`),
                    booleanFormation: watch(`level[${indexLevel}].blocks[${index}].booleanFormation`),
                    booleanVideo: watch(`level[${indexLevel}].blocks[${index}].booleanVideo`),
                    booleanInformation: watch(`level[${indexLevel}].blocks[${index}].booleanInformation`),
                    blockLevel: watch(`level[${indexLevel}].blocks[${index}].blockLevel`)
                })
                return (
                    <div key={item?.id} style={{
                        border: '.5px solid grey',
                        width: '100%'
                    }}>
                        <Accordion>
                            <div style={{display: 'flex'}}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls='panel1a-content'
                                    id='panel1a-header'
                                    style={{flex: '1 1 100%'}}
                                >
                                    <h3> Bloc {(index + 1)}</h3>
                                </AccordionSummary>
                                <Button onClick={() => remove(index)} color='primary'>
                                    <DeleteIcon/>
                                </Button>

                            </div>
                            <AccordionDetails>
                                <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                                    <FormField id={`level[${indexLevel}].blocks[${index}].advice`} label='Information'
                                               style={styles.formField}>
                                        <FormInput
                                            name={`level[${indexLevel}].blocks[${index}].advice`}
                                            type='text'
                                            control={control}
                                            defaultValue={_.get(item, 'advice', '')}
                                            rules={{
                                                maxLength: {
                                                    value: 70,
                                                    message: 'Maximum 70 caracteres'
                                                }
                                            }}
                                        />
                                        <FormError
                                            error={_.get(errors, ['level', indexLevel, 'blocks', index, 'advice'])}/>
                                    </FormField>

                                    <FormField id={`level[${indexLevel}].blocks[${index}].blockLevel`}
                                               label='Niveau du bloc' style={styles.formField}>
                                        <Controller
                                            control={control}
                                            name={`level[${indexLevel}].blocks[${index}].blockLevel`}
                                            defaultValue={item?.blockLevel}
                                            rules={{
                                                validate: value =>
                                                    (value > 0 && value < 9) || 'Doit etre entre 1 et 8',
                                                required: {
                                                    value: true,
                                                    message: 'Ce champ est requis'
                                                }
                                            }}
                                            render={(
                                                {onChange, ref}
                                            ) => (
                                                <Select
                                                    getOptionLabel={option => option.name}
                                                    getOptionValue={option => option._id}
                                                    options={options.blockLevel}
                                                    defaultValue={_.filter(options.blockLevel, level => level._id === item.blockLevel)}
                                                    onChange={selected => onChange(selected._id)}
                                                    inputRef={ref}
                                                />
                                            )}
                                        />
                                        <FormError
                                            error={_.get(errors, ['level', indexLevel, 'blocks', index, 'blockLevel'])}/>
                                    </FormField>

                                    {
                                        (_.get(watcher, [index, 'blockLevel'], false) === 7 || _.get(watcher, [index, 'blockLevel'], false) === 8) && nbLevels > 1 && (

                                            <>
                                                <FormField name={`level[${indexLevel}].blocks[${index}].labelBtn`}
                                                           label='Label'
                                                           style={styles.formField}
                                                >
                                                    <FormInput
                                                        name={`level[${indexLevel}].blocks[${index}].labelBtn`}
                                                        type='text'
                                                        defaultValue={_.get(item, 'labelBtn', '')}
                                                        rules={{}}
                                                        control={control}
                                                    />
                                                    {/*<FormError error={errors.name}/>*/}
                                                </FormField>
                                                <FormField name={`level[${indexLevel}].blocks[${index}].type`}
                                                           label='Type' style={styles.formField}>
                                                    <Controller
                                                        control={control}
                                                        defaultValue={item.type ? typeOptions.filter(option => option._id === item.type)[0]._id : typeOptions[0]._id}
                                                        name={`level[${indexLevel}].blocks[${index}].type`}
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message: 'Ce champ est requis'
                                                            }
                                                        }}
                                                        render={(
                                                            {onChange, ref}
                                                        ) => (
                                                            <Select
                                                                getOptionLabel={option => option.name}
                                                                getOptionValue={option => option._id}
                                                                options={typeOptions}
                                                                defaultValue={item.type ? typeOptions.filter(option => option._id === item.type)[0] : typeOptions[0]}
                                                                onChange={selected => {
                                                                    onChange(selected._id);
                                                                }}
                                                                inputRef={ref}
                                                            />
                                                        )}
                                                    />
                                                </FormField>

                                            </>
                                        )}
                                    {
                                        (_.get(watcher, [index, 'blockLevel'], false) === 7) && nbLevels > 1 && (

                                            <FormField name={`level[${indexLevel}].blocks[${index}].targetAnchor`}
                                                       label='Level cible' style={styles.formField}>
                                                <Controller
                                                    control={control}
                                                    defaultValue={item.targetAnchor ? targetOptions.filter(option => option._id === item.targetAnchor)[0]._id : targetOptions[0].name}
                                                    name={`level[${indexLevel}].blocks[${index}].targetAnchor`}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: 'Ce champ est requis'
                                                        }
                                                    }}
                                                    render={(
                                                        {onChange, ref}
                                                    ) => (
                                                        <Select
                                                            getOptionLabel={option => option.name}
                                                            getOptionValue={option => option._id}
                                                            options={targetOptions}
                                                            defaultValue={item.targetAnchor ? targetOptions.filter(option => option._id === item.targetAnchor)[0] : targetOptions[0]}
                                                            onChange={selected => {
                                                                onChange(selected._id);
                                                            }}
                                                            inputRef={ref}
                                                        />
                                                    )}
                                                />
                                            </FormField>


                                        )
                                    }

                                    {
                                        _.get(watcher, [index, 'blockLevel'], false) === 8 && nbLevels > 1 && (

                                            <FormField name={`level[${indexLevel}].blocks[${index}].externLink`}
                                                       label='Lien externe' style={styles.formField}>
                                                <FormInput
                                                    name={`level[${indexLevel}].blocks[${index}].externLink`}
                                                    type='text'
                                                    defaultValue={_.get(item, 'externLink', '')}
                                                    rules={{}}
                                                    control={control}
                                                />
                                                {/*<FormError error={errors.name}/>*/}
                                            </FormField>

                                        )
                                    }


                                    <BooleanInput
                                        style={styles.booleanInput}
                                        label='Ajouter un consensus'
                                        field={`level[${indexLevel}].blocks[${index}].booleanConsensus`}
                                        toChange={`level[${indexLevel}].blocks[${index}].booleanConsensus`}
                                        register={register}
                                        control={control}
                                        defaultValue={_.get(item, 'booleanConsensus', false)}
                                    />
                                    {
                                        _.get(watcher, [index, 'booleanConsensus'], false) && (
                                            <FormField id={`level[${indexLevel}].blocks[${index}].consensus`}
                                                       label='Consensus'>
                                                <FormInput
                                                    name={`level[${indexLevel}].blocks[${index}].consensus`}
                                                    type='text'
                                                    control={control}
                                                    defaultValue={_.get(item, 'consensus', '')}
                                                />
                                                <FormError
                                                    error={_.get(errors, ['level', indexLevel, 'blocks', index, 'consensus'])}/>
                                            </FormField>
                                        )
                                    }

                                    <BooleanInput
                                        style={styles.booleanInput}
                                        label='Ajouter un telechargement'
                                        field={`level[${indexLevel}].blocks[${index}].booleanDownload`}
                                        toChange={`level[${indexLevel}].blocks[${index}].booleanDownload`}
                                        register={register}
                                        control={control}
                                        defaultValue={_.get(item, 'booleanDownload', false)}
                                    />
                                    {
                                        _.get(watcher, [index, 'booleanDownload'], false) && (
                                            <>
                                                <Controller
                                                    name={`level[${indexLevel}].blocks[${index}].currentDownload`}
                                                    render={({onChange, name, ref}) => (
                                                        <Dropzone
                                                            ref={ref}
                                                            name={name}
                                                            multiple
                                                            onChangeStatus={(file, status, allFiles) => {
                                                                handleControlledDropzonChangeStatus(status, allFiles, onChange)
                                                            }}
                                                        />
                                                    )}
                                                    control={control}
                                                />
                                                <h4>Files are:</h4>
                                                <Downloads
                                                    indexLevel={indexLevel}
                                                    indexBlock={index}
                                                    control={control}
                                                />

                                            </>
                                        )
                                    }

                                    <BooleanInput
                                        style={styles.booleanInput}
                                        label='Ajouter une formation'
                                        field={`level[${indexLevel}].blocks[${index}].booleanFormation`}
                                        toChange={`level[${indexLevel}].blocks[${index}].booleanFormation`}
                                        register={register}
                                        control={control}
                                        defaultValue={_.get(item, 'booleanFormation', false)}
                                    />
                                    {
                                        _.get(watcher, [index, 'booleanFormation'], false) && (
                                            <FormField
                                                name={`level[${indexLevel}].blocks[${index}].formation`}
                                                label='Formation'
                                                style={styles.formField}
                                            >
                                                <Controller
                                                    control={control}
                                                    defaultValue={_.get(item, 'formation', false)}
                                                    name={`level[${indexLevel}].blocks[${index}].formation`}
                                                    rules={{}}
                                                    render={(
                                                        {onChange, ref}
                                                    ) => (
                                                        <Select
                                                            getOptionLabel={option => option.name}
                                                            getOptionValue={option => option}
                                                            options={formationsOptions}
                                                            defaultValue={item.formation ? formationsOptions.filter(option => option._id === item.formation)[0] : {}}
                                                            onChange={selected => {
                                                                onChange(selected);
                                                            }}
                                                            inputRef={ref}
                                                        />
                                                    )}
                                                />
                                            </FormField>
                                        )
                                    }

                                    <BooleanInput
                                        style={styles.booleanInput}
                                        label='Ajouter une information'
                                        field={`level[${indexLevel}].blocks[${index}].booleanInformation`}
                                        toChange={`level[${indexLevel}].blocks[${index}].booleanInformation`}
                                        register={register}
                                        control={control}
                                        defaultValue={_.get(item, 'booleanInformation', false)}
                                    />
                                    {
                                        _.get(watcher, [index, 'booleanInformation'], false) && (
                                            <FormField id={`level[${indexLevel}].blocks[${index}].information`}
                                                       label='Information' style={styles.formField}>
                                                <FormInput
                                                    name={`level[${indexLevel}].blocks[${index}].information`}
                                                    type='text'
                                                    control={control}
                                                    defaultValue={_.get(item, 'information', '')}
                                                />
                                                <FormError
                                                    error={_.get(errors, ['level', indexLevel, 'blocks', index, 'information'])}/>
                                            </FormField>
                                        )
                                    }

                                    <BooleanInput
                                        style={styles.booleanInput}
                                        label='Ajouter une video'
                                        field={`level[${indexLevel}].blocks[${index}].booleanVideo`}
                                        toChange={`level[${indexLevel}].blocks[${index}].booleanVideo`}
                                        register={register}
                                        control={control}
                                        defaultValue={_.get(item, 'booleanVideo', false)}
                                    />
                                    {
                                        _.get(watcher, [index, 'booleanVideo'], false) && (
                                            <FormField id={`level[${indexLevel}].blocks[${index}].video`} label='Video'
                                                       style={styles.formField}>
                                                <FormInput
                                                    name={`level[${indexLevel}].blocks[${index}].video`}
                                                    type='text'
                                                    control={control}
                                                    defaultValue={_.get(item, 'video', '')}
                                                />
                                                <FormError
                                                    error={_.get(errors, ['level', indexLevel, 'blocks', index, 'video'])}/>
                                            </FormField>
                                        )
                                    }

                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                )
            })}
            {
                fields.length < 2
                    ? (
                        <div style={{
                            display: 'flex',
                            alignSelf: 'center',
                            justifyContent: 'center',
                            margin: '20px 0'
                        }}>
                            <Button
                                color='secondary'
                                startIcon={<AddIcon/>}
                                variant='contained'
                                onClick={() => {
                                    append({})
                                }}
                            >
                                Ajouter un bloc
                            </Button>
                        </div>
                    )
                    : ''
            }
        </div>
    )
}

export default BlockInput
