
import _ from 'lodash'
import Promise from 'bluebird'

const convertFileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject

    reader.readAsDataURL(file)
  })

const loopEachKeys = async (uploadsKeys, datas, key) => {
  return Promise.each(datas[key], async (item, index) => {
    if (item.blocks) {
      return Promise.map(item.blocks, (block, indexBlocks) => {
        return Promise.map(_.get(block, 'download', []), (dl, indexDl) => {
          key = 'level[' + index + '].blocks[' + indexBlocks + '].download'
          if (_.get(dl, 'file', null)) {
            uploadsKeys.push(key)
            return convertFileToBase64(dl.file)
              .then(fileBase64 => {
                _.set(datas, ['level', index, 'blocks', indexBlocks, 'download', indexDl], { src: fileBase64, title: dl.file.name })
                return datas
              })
          } else {
            return true
          }
        })
      })
    }
    if (_.get(item, 'rawFile', null)) {
      uploadsKeys.push(key)
      return convertFileToBase64(item.rawFile)
        .then(fileBase64 => {
          datas[key][index] = { src: fileBase64, title: item.rawFile.name }
          return datas
        })
    } else {
      return true
    }
  })
}

const uploadMedias = (datas) => {
  const uploadsKeys = []
  console.log(datas)
  return new Promise(async (resolve, reject) => {
    await Promise.each(Object.keys(datas), async key => {
      if (_.isArray(datas[key])) {
        await loopEachKeys(uploadsKeys, datas, key)
      } else {
        if (_.get(datas[key], 'rawFile', null)) {
          uploadsKeys.push(key)
          await convertFileToBase64(datas[key].rawFile)
            .then(file1 => {
              return (datas[key] = { src: file1, title: datas[key].rawFile.name })
            })
        }
        return true
      }
    })
    datas = { ...datas, uploadsKeys }
    console.log(datas)
    resolve(datas)
  })
}

export default uploadMedias
