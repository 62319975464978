import * as React from 'react'
import {
  SelectInput,
  Edit,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  useTranslate,
  ImageInput,
  ImageField,
  DateInput,
  NumberInput
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'

const FormationTitle = ({ record }) => {
  return <span>Modifier la formation {record ? `"${record.name}"` : ''}</span>
}

export const FormationEdit = props => {
  const translate = useTranslate()
  return (
    <Edit title={<FormationTitle />} {...props}>
      <SimpleForm>
        <div className='form_field_container'>
          <div className='field_container'>
            <TextInput
              label={translate('resources.formations.fields.title')}
              source='title'
            />
            <TextInput
                label={translate('resources.guides.fields.keyGa')}
                source='keyGa'
            />
            <ReferenceInput
              label={translate(
                'resources.formations.fields.formationType'
              )}
              source='formationType'
              reference='metadatas'
              filterToQuery={q => {
                return {
                  onModel: 'formation'
                }
              }}
              link={false}
            >
              <SelectInput optionText='name' />
            </ReferenceInput>

            <TextInput
              label={translate('resources.formations.fields.public')}
              source='public'
            />

            <TextInput
              label={translate(
                'resources.formations.fields.prerequisite'
              )}
              source='prerequisite'
            />

            <TextInput
              label={translate(
                'resources.formations.fields.description'
              )}
              source='description'
            />



            <ArrayInput label={translate('resources.formations.fields.goals')} source='goals'>
              <SimpleFormIterator>
                <TextInput label={translate('resources.formations.fields.name')} source=''/>
              </SimpleFormIterator>
            </ArrayInput>


            <ArrayInput label={translate('resources.formations.fields.acquiredSkills')} source='acquiredSkills'>
              <SimpleFormIterator>
                <TextInput label={translate('resources.formations.fields.name')} source=''/>
              </SimpleFormIterator>
            </ArrayInput>


            <RichTextInput
              label={translate('resources.formations.fields.program')}
              source='program'
              toolbar={[
                ['bold'], // toggled buttons
                [{ list: 'bullet' }],
                [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
                [{ header: [2, 3, false] }],
                ['clean'] // remove formatting button
              ]}
            />

            <ArrayInput
              label={translate(
                'resources.formations.fields.speakers'
              )}
              source='speakers'
            >
              <SimpleFormIterator>
                <TextInput
                  label={translate(
                    'resources.formations.fields.name'
                  )}
                  source=''
                />
              </SimpleFormIterator>
            </ArrayInput>

            <RichTextInput
              label={translate('resources.formations.fields.informations')}
              source='informations'
              toolbar={[
                ['bold'], // toggled buttons
                [{ list: 'bullet' }],
                [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
                [{ header: [2, 3, false] }],
                ['clean'] // remove formatting button
              ]}
            />

            <ImageInput
              label={translate('resources.formations.fields.slider')}
              source='slider'
              multiple
              accept={['.jpg', '.jpeg', '.JPG', '.png', '.gif']}
              labelSingle={translate('resources.news.fields.uploadImage')}
              maxSize={5000000}
              children={<ImageField source='src' />}
            />

            <ArrayInput
              label={translate('resources.formations.fields.opinions')}
              source='notes'
            >
              <SimpleFormIterator>
                <TextInput
                  label={translate('resources.formations.fields.username')}
                  source='username'
                />
                <NumberInput
                  label={translate('resources.formations.fields.numberOfStars')}
                  source='numberOfStars'
                />
                <TextInput
                  label={translate('resources.formations.fields.description')}
                  source='description'
                />
                <DateInput
                  label={translate('resources.formations.fields.createAt')}
                  source='createAt'
                  initialValue={new Date()}
                />
              </SimpleFormIterator>
            </ArrayInput>

          </div>
        </div>
      </SimpleForm>
    </Edit>
  )
}
