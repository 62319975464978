import * as React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  useTranslate,
  ImageInput,
  ImageField
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'

const CommitteeTitle = ({ record }) => {
  return <span>Modifier la comité {record ? `"${record.name}"` : ''}</span>
}

export const CommitteeEdit = (props) => {
  const translate = useTranslate()
  return (
    <Edit title={<CommitteeTitle />} {...props}>
      <SimpleForm redirect='show' enctype='multipart/form-data'>
        <div
          className='form_field_container' style={{
            flexDirection: 'column'
          }}
        >
          <div className='field_container'>

            <TextInput label={translate('resources.committee.fields.name')} source='name' />
            <TextInput label={translate('resources.committee.fields.function')} source='function' />
            <NumberInput label={translate('resources.committee.fields.order')} source='order' />
            <ImageInput
              label={translate('resources.committee.fields.mainImage')}
              source='mainImage'
              accept={[
                '.jpg',
                '.jpeg',
                '.JPG',
                '.png',
                '.gif'
              ]}
              labelSingle={translate('resources.committee.fields.uploadImage')}
              maxSize={5000000}
              children={<ImageField source='src' />}
            />
          </div>
          <RichTextInput
            label={translate('resources.committee.fields.content')}
            source='content' toolbar={[
              ['bold', 'italic', 'underline'], // toggled buttons
              ['link', 'image', 'video'],
              [{ list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
              [{ header: [2, 3, false] }],
              ['clean'] // remove formatting button
            ]}
          />
        </div>
      </SimpleForm>
    </Edit>
  )
}

/*
 <TextInput source="username" validate={required()} />
            <TextInput multiline source="teaser" validate={required()} />
            <RichTextInput source="body" validate={required()} />
 */
