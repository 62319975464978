import {TranslationMessages} from 'react-admin';
import frenchMessages from 'ra-language-french';

const customFrenchMessages: TranslationMessages = {
    ...frenchMessages,
    form: {
        validation: {
            password: 'Doit contenir 8 caractères minimum'
        }
    },
    pos: {
        search: 'ReRecherche',
        configuration: 'Configuration',
        language: 'Langue',
        theme: {
            name: 'Theme',
            light: 'Clair',
            dark: 'Obscur',
        },
        dashboard: {
            metadatas: 'Méta-données',
            users: 'Utilisateurs',
            events: 'Événements',
            settings: 'Paramètres',
            formations: 'Formations',
            staticPages: 'Pages',
            news: 'Actualités',
            me: 'Mon profil',
            guides: 'Guides',
            committee: 'Comités',
            accompaniments: 'Accompagnement',
            welcome: {
                title: 'Bienvenue sur la démo e-commerce de react-admin',
                subtitle:
                    "Ceci est le back-office d'un magasin de posters imaginaire. N'hésitez pas à explorer et à modifier les données. La démo s'exécute en local dans votre navigateur, et se remet à zéro chaque fois que vous rechargez la page.",
                ra_button: 'Site web de react-admin',
                demo_button: 'Code source de cette démo',
            },
        },
        menu: {
            sales: 'Ventes',
            catalog: 'Catalogue',
            customers: 'Clients',
            users: 'Utilisateurs'
        },
    },
    resources: {
        customers: {
            name: 'Client |||| Clients',
            fields: {
                address: 'Rue',
                birthday: 'Anniversaire',
                city: 'Ville',
                stateAbbr: 'Etat',
                commands: 'Commandes',
                first_name: 'Prénom',
                first_seen: 'Première visite',
                groups: 'Segments',
                has_newsletter: 'Abonné à la newsletter',
                has_ordered: 'A commandé',
                last_name: 'Nom',
                last_seen: 'Vu le',
                last_seen_gte: 'Vu depuis',
                latest_purchase: 'Dernier achat',
                name: 'Nom',
                total_spent: 'Dépenses',
                zipcode: 'Code postal',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identité',
                address: 'Adresse',
                stats: 'Statistiques',
                history: 'Historique',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        settings: {
            name: 'Paramètres',
            rppsForm: 'Base de données des RPPS',
        },
        users: {
            name: 'Utilisateur |||| Utilisateurs',
            fields: {
                name: 'Nom',
                _id: 'Numéro',
                firstName: 'Prénom',
                lastName: 'Nom',
                email: 'Email',
                role: 'Rôle',
                search: 'Recherche',
                civility: 'Civilité',
                rppsNumber: 'Numéro RPPS',
                password: 'Mot de passe',
                profession: 'Profession',
                exerciceMode: "Mode d'exercice",
                establishment: "Etablissement",
                association: "Assocication",
                businessAddress: "Adresse professionnelle",
                zipcode: "Code postale",
                city: "Ville",
                receiveNewsletter: "Reçevoir les newsletters"
            },
            filters: {},
            fieldGroups: {
                identity: 'Identité',
                address: 'Adresse',
                stats: 'Statistiques',
                history: 'Historique',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',

            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        news: {
            name: 'Actualité |||| Actualités',
            amount: '1 actualité |||| %{smart_count} actualités',
            title: 'Actualité n°%{reference}',
            fields: {
                title: 'Titre',
                resume: 'Résumé',
                content: 'Contenu',
                createAt: 'Créé le',
                expirationDate: 'Date d\'expiration',
                search: 'Recherche',
                mainImage: 'Image principale',
                uploadImage: 'Ajouter une image',
                type: 'Type d\'actualité'
            },
            section: {
                order: 'Commande',
                customer: 'Client',
                shipping_address: 'Adresse de livraison',
                items: 'Articles',
                total: 'Total',
            },
        },
        committee: {
            name: 'Comité |||| Comités',
            amount: '1 comité |||| %{smart_count} comités',
            title: 'Comité n°%{reference}',
            fields: {
                name: 'Nom',
                function: 'Fonction',
                order: 'Ordre d\'affichage',
                content: 'Contenu',
                uploadUrl: 'Image principale',
                mainImage: 'Image principale',
                uploadImage: 'Ajouter une image',
                createAt: 'Créé le',
                search: 'Recherche',
            },
            section: {
                order: 'Commande',
                customer: 'Client',
                shipping_address: 'Adresse de livraison',
                items: 'Articles',
                total: 'Total',
            },
        },
        events: {
            name: 'Événement |||| Événements',
            fields: {
                _id: 'Numéro',
                name: 'Nom',
                formation: 'Formation',
                startAt: 'Commence le',
                location: 'Lieu',
                duration: 'Durée',
                dateInfo: 'Informations sur la date',
                targetPublic: 'Public',
                participants: 'Participants',
                goals: 'Objectifs',
                search: 'Recherche',
                accompaniment: 'Accompagnement',
                eventType: 'Type de l\'évenement'

            },
        },
        metadatas: {
            name: 'Méta-donnée |||| Méta-données',
            fields: {
                _id: 'Numéro',
                name: 'Nom',
                search: 'Recherche',
                onModel: 'Pour le modèl',
                onField: 'Pour le champs'
            },
            filters: {
                onModel: 'Catégories'
            },
        },
        guides: {
            fields: {
                name: 'Nom du guide',
                keyGa: 'Clé google analytics',
                display: 'Afficher',
                guideType: 'Type de dialyse',
                level: 'Ligne',
                elementPositionId: 'Structure',
                blocks: 'Eléments',
                blockLevel: 'Niveau de l\'élément',
                booleanDownload: 'Ajouter un téléchargment',
                download: 'Téléchargment',
                booleanInformation: 'Ajouter une information',
                information: 'Information',
                booleanVideo: 'Ajouter une vidéo',
                video: 'Vidéo',
                booleanConsensus: 'Ajouter un consensus',
                consensus: 'Consensus',
                booleanFormation: 'Ajouter une formation',
                formation: 'Formation',
                booleanLastLine: 'Ajouter derniere ligne',
                booleanReturnLine: 'Ajouter une ligne de retour',
                booleanPrincipalReturnLine: 'Ajouter une ligne sur la droite du guide',
                position: 'Position',
                type: 'Type',
                info: 'Information',
                standardLines: 'Ligne standard',
                lastLines: 'Derniere ligne',
                returnLines: 'Ligne de retour',
                principalReturnLines: 'Ligne sur la droite du guide',
                booleanStandardLine: 'Ajouter une ligne standard',
                advice: 'Information'
            },
            validate: {
                info: '30 caractères maximum'
            },
            values: {
                left: 'Gauche',
                right: 'Droite',
                center: 'Centrer',
                positive: 'Positif',
                negative: 'Negatif',
                standard: 'Normal'
            }
        },
        formations: {
            name: 'Formation |||| Formations',
            fields: {
                name: 'Nom',
                title: 'Titre',
                keyGa: 'Clé google analytics',
                public: 'Publique',
                description: 'Description',
                formationType: 'Type de la formation',
                prerequisite: 'Pré-requis',
                goals: 'Objectifs',
                program: 'Programme',
                informations: 'Informations',
                actions: 'Actions',
                acquiredSkills: 'Compétences acquises',
                speakers: 'Intervenants',
                natureOfAction: "Nature de l'action",
                orientations: 'Orientations de la haute autorité de santé',
                pedagogy: 'Pédagogie',
                evaluation: 'Évaluation',
                search: 'Recherche',
                slider: 'Certificats',
                opinions: 'Avis',
                username: 'Nom',
                numberOfStars: 'Nombre d\'étoiles',
                createAt: 'Créé le'
            },
        },
        accompaniments: {
            name: 'Accompagnement |||| Accompagnements',
            fields: {
                accompanimentType: 'Type d\'accompagnement',
                keyGa: 'Clé google analytics',
                opinions: 'Avis',
                username: 'Nom',
                numberOfStars: 'Nombre d\'étoiles',
                createAt: 'Créé le'
            }
        },
        staticPages: {
            name: 'Page |||| Pages',
            amount: '1 commentaire |||| %{smart_count} commentaires',
            relative_to_poster: 'Commentaire sur',
            detail: 'Détail du commentaire',
            fields: {
                key: 'Clé',
                name: 'Nom',
                content: 'Contenu',
                mainImage: 'Image principale',
                attachedPdfs: 'PDF attachés',
                url: 'Url'

            }
        },
        segments: {
            name: 'Segment |||| Segments',
            fields: {
                customers: 'Clients',
                name: 'Nom',
            },
            data: {
                compulsive: 'Compulsif',
                collector: 'Collectionneur',
                ordered_once: 'A commandé',
                regular: 'Régulier',
                returns: 'A renvoyé',
                reviewer: 'Commentateur',
            },
        },
    },
    custom: {
        label: {
            firstName: 'Prénom',
            lastName: 'Nom',
            civility: 'Civilité',
            profession: 'Profession',
            password: 'Mot de passe',
            confirmPassword: 'Confirmer le mot de passe',
            email: 'Email'
        },
        action: {
            login: 'Connexion',
            forgotPassword: 'Mot de passe oublié ?',
            requestPassword: 'Demander un nouveau mot de passe',
            changeMyPassword: 'Changer mon mot de passe',
        }
    }
};

export default customFrenchMessages;
