import React from 'react'
import { Route } from 'react-router'
import ForgotPassword from '../component/authentication/ForgotPassword'
import MeComponent from '../component/page/MeComponent'
import ResetPassword from '../component/authentication/ResetPassword'
import {Settings} from "../component/page/Settings";


export const customRoutes = [
  <Route path='/forgot-password' component={ForgotPassword} noLayout />,
  <Route path='/reset-password' component={ResetPassword} noLayout />,
  <Route path='/me' component={MeComponent} />,
  <Route path='/settings' component={Settings} />
];
