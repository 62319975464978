import * as React from 'react'
import {
  Show,
  SimpleShowLayout
} from 'react-admin'
import TextField from './../fields/TextField'

const MetaDataTitle = ({ record }) => {
  return <span>Méta-donnée {record ? `"${record.name}"` : ''}</span>
}

export const MetaDataShow = (props) => (
  <Show title={<MetaDataTitle />} {...props}>
    <SimpleShowLayout className='show_field_container'>
      <TextField label='resources.metadatas.fields.name' source='name' />
      <TextField label='resources.metadatas.fields.onModel' source='onModel' />
      <TextField label='resources.metadatas.fields.onField' source='onField' />
    </SimpleShowLayout>
  </Show>
)
