import {Controller} from 'react-hook-form'

const FormInput = ({name, type, rules, control, defaultValue, disabled, style = {}}) => {
    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            defaultValue={defaultValue}
            render={(
                {onChange, ref}
            ) => (
                <input
                    style={{
                        ...style,
                        border:'.5px solid',
                        borderColor: 'hsl(0, 0%, 80%)',
                        borderRadius: 5,
                        width: '98%',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        paddingLeft: 5
                    }}
                    disabled={disabled}
                    type={type}
                    inputRef={ref}
                    onChange={(e) => onChange(e.target.value)}
                    defaultValue={defaultValue}
                />
            )}
        />
    )
}

export default FormInput
