import * as React from 'react'
import {
  Show,
  SimpleShowLayout,
  RichTextField,
  ImageField,
  NumberField
} from 'react-admin'
import TextField from '../fields/TextField'

const CommitteeTitle = ({ record }) => {
  return <span>Comité {record ? `"${record.name}"` : ''}</span>
}

export const CommitteeShow = (props) => (
  <Show title={<CommitteeTitle />} {...props}>
    <SimpleShowLayout className='show_field_container'>
      <TextField label='resources.committee.fields.name' source='name' />
      <TextField label='resources.committee.fields.function' source='function' />
      <NumberField label='resources.committee.fields.order' source='order' />
      <ImageField
        source='mainImage.src'
        label='resources.committee.fields.mainImage'
      />
      <RichTextField className='rich-text' source='content' />

    </SimpleShowLayout>
  </Show>
)

/*

            <RichTextField source="body" />
            <DateField label="Publication date" source="created_at" />
 */
