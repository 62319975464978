import * as React from 'react'
import {
  Show,
  SimpleShowLayout,
  ReferenceField,
  ArrayField,
  Datagrid
} from 'react-admin'
import TextField from './../fields/TextField'
import SimpleItemField from "../fields/SimpleItemField";

const FormationTitle = ({ record }) => {
  return <span>Formation {record ? `"${record.name}"` : ''}</span>
}

export const FormationShow = (props) => (
  <Show title={<FormationTitle />} {...props}>
    <SimpleShowLayout className='show_field_container'>
      <TextField label='resources.formations.fields.title' source='title'  />
      <TextField label='resources.formations.fields.keyGa' source='keyGa'  />
      <ReferenceField
        label='resources.formations.fields.formationType'
        link='show'
        className='referenceField'
        source='formationType' reference='metadatas'
      >
        <TextField source='name' noMarginBottom />
      </ReferenceField>
      <TextField label='resources.formations.fields.public' source='public'  />
      <TextField label='resources.formations.fields.description' source='description'  />
      <TextField label='resources.formations.fields.prerequisite' source='prerequisite' />

      <ArrayField source='goals' className='dataGridField'>
        <Datagrid>
          <SimpleItemField/>
        </Datagrid>
      </ArrayField>

      <ArrayField source='acquiredSkills' className='dataGridField'>
        <Datagrid>
          <SimpleItemField/>
        </Datagrid>
      </ArrayField>

      <ArrayField source='speakers' className='dataGridField'>
        <Datagrid>
          <SimpleItemField/>
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
)
