import * as React from 'react'
import {
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    SelectInput,
    ReferenceInput,
    ArrayInput,
    AutocompleteArrayInput,
    SimpleFormIterator,
    useTranslate,
    RadioButtonGroupInput,
    FormDataConsumer
} from 'react-admin'
import _ from 'lodash'
import Promise from 'bluebird'
import axios from '../../libs/axios'
import {useEffect, useState} from "react";

const EventTitle = ({record}) => {
    return <span>Modifier l'evenement {record ? `"${record.name}"` : ''}</span>
}

export const EventEdit = (props) => {


    const translate = useTranslate();

    let [participantsChoices, setParticipantsChoices] = useState();

    useEffect(() => {
        const getUserParams = {
            _end: 10,
            _order: 'ASC',
            _sort: 'id',
            _start: 0
        }

        Promise.props({
            participants: axios.get('/users', {params: getUserParams})
        }).then(res => {
            const users = _.get(res, 'participants.data', [])
            if (users.length > 0) {
                setParticipantsChoices(users)
            }
        })
    }, [])


    return (

        <Edit title={<EventTitle/>} {...props}>

            <SimpleForm redirect='show'>
                <div className='form_field_container'>
                    <div className='field_container'>
                        <TextInput
                            label={translate('resources.events.fields.name')}
                            source='name'
                        />
                        <FormDataConsumer>
                            {({formData, ...rest}) => {
                                return (
                                    <RadioButtonGroupInput
                                        label={translate('resources.events.fields.eventType')}
                                        source="eventType"
                                        choices={[
                                            {id: 'formation', name: 'Formation'},
                                            {id: 'accompaniment', name: 'Accompagnement'}
                                        ]}
                                        style={{
                                            textAlign: 'left'
                                        }}
                                        initialValue={formData.formationLink ? 'formation' : 'accompaniment'}
                                    />
                                )
                            }}
                        </FormDataConsumer>


                        <FormDataConsumer>
                            {({formData, ...rest}) => {
                                if (formData.eventType === 'formation') {
                                    formData.accompanimentLink = null
                                } else (
                                    formData.formationLink = null
                                )
                                return (
                                    formData.eventType === 'formation' ? (
                                        <ReferenceInput label={translate('resources.events.fields.formation')}
                                                        source='formationLink' reference='formations'>
                                            <SelectInput optionText='title'/>
                                        </ReferenceInput>
                                    ) : (
                                        <ReferenceInput label={translate('resources.events.fields.accompaniment')}
                                                        source='accompanimentLink' reference='accompaniments'>
                                            <SelectInput optionText='title'/>
                                        </ReferenceInput>
                                    )
                                )
                            }}
                        </FormDataConsumer>


                        <DateInput label={translate('resources.events.fields.startAt')} source='startAt'/>
                        <TextInput label={translate('resources.events.fields.location')} source='location'/>
                        <TextInput label={translate('resources.events.fields.duration')} source='duration'/>
                        <TextInput label={translate('resources.events.fields.dateInfo')} source='dateInfo'/>
                        <TextInput label={translate('resources.events.fields.targetPublic')}
                                   source='targetPublic'/>
                        <ArrayInput label={translate('resources.events.fields.goals')} source='goals'>
                            <SimpleFormIterator>
                                <TextInput label={translate('resources.events.fields.name')} source=''/>
                            </SimpleFormIterator>
                        </ArrayInput>
                        {
                            participantsChoices ? (
                                <AutocompleteArrayInput
                                    label={translate('resources.events.fields.participants')}
                                    source='participants'
                                    optionText='email'
                                    fullWidth
                                    choices={participantsChoices}
                                />
                            ) : null
                        }
                    </div>
                </div>
            </SimpleForm>
        </Edit>
    )

}


