import React, { useState } from 'react'
import { useLogin, useNotify, Notification, useTranslate } from 'react-admin'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton'
import OutlinedInput from '@material-ui/core/OutlinedInput'

const LoginComponent = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const translate = useTranslate()
  const login = useLogin()
  const notify = useNotify()
  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false
  })

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  const submit = e => {
    e.preventDefault()
    login({ email, password }).catch(() =>
      notify('Identifiants incorrect.')
    )
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}

      className='login_background'
    >
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          height: '500px',
          borderRadius: 10,
          width: '50%',
          background: 'white'
        }}
        className='login_form'
        onSubmit={submit}
      >
        <AccountBoxIcon style={{
          fontSize: '100'
        }}
        />

        <TextField
          id='email'
          name='email'
          label={translate('custom.label.email')}
          variant='outlined'
          style={{
            width: '40%'
          }}
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <FormControl
          variant='outlined'
          style={{
            width: '40%'
          }}
        >
          <InputLabel htmlFor='standard-adornment-password'>{translate('custom.label.password')}</InputLabel>
          <OutlinedInput
            id='password'
            name='password'
            type={values.showPassword ? 'text' : 'password'}
            value={password}
            onChange={e => setPassword(e.target.value)}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge='end'
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </FormControl>

        <Button size='large' type='submit' color='primary'>
          {translate('custom.action.login')}

        </Button>
        <Link
          style={{
            fontSize: 12
          }}
          to='/forgot-password'
        > {translate('custom.action.forgotPassword')}
        </Link>
        <Notification />
      </form>
    </div>
  )
}

export default LoginComponent
