import Promise from 'bluebird';
import axios from './axios';
import _ from 'lodash';

const authProvider = {
    // authentication
    login: ({email, password}) => {
        if (email && password) {
            return axios.post(_.get(process.env, 'REACT_APP_BACK_URL') + '/account/authenticate', {
                email,
                password
            }).then(resp => {
                if (resp) {
                    let xApiKey = _.get(resp, 'headers.x-api-key', null);
                    let xRefreshToken = _.get(resp, 'headers.x-refresh-token', null);
                    let xExpireRefresh = _.get(resp, 'headers.x-expire-refresh', null);
                    localStorage.setItem('xApiKey', xApiKey)
                    localStorage.setItem('xRefreshToken', xRefreshToken)
                    localStorage.setItem('xExpireRefresh', xExpireRefresh)
                    localStorage.setItem('currentUser', JSON.stringify(resp.data))
                    return Promise.resolve()
                } else {
                    return Promise.reject()
                }
            }).catch(err => {
                return Promise.reject()
            })
        } else {
            return Promise.reject('Email ou mot de passe incorrecte')
        }
    },
    checkError: error => {
        const { status } = error;
        if(status === 401 || status === 403) {
            localStorage.removeItem('xRefreshToken');
            localStorage.removeItem('xApiKey');
            localStorage.removeItem('currentUser');
            localStorage.removeItem('xExpireRefresh');
            return Promise.reject();
        }  else {
            return Promise.resolve();
        }

    },
    checkAuth: params => {
        return  (localStorage.getItem('xApiKey') &&
        localStorage.getItem('xRefreshToken') &&
        localStorage.getItem('xExpireRefresh') &&
        localStorage.getItem('currentUser')) ? Promise.resolve() :   Promise.reject()

    },
    logout: () => {
        localStorage.removeItem('xRefreshToken');
        localStorage.removeItem('xApiKey');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('xExpireRefresh');
        return Promise.resolve();
    },
    getIdentity: () => {
        return Promise.resolve();
    },
    // authorization
    getPermissions: params => {
        let currentUserString = localStorage.getItem('currentUser');
        let currentUser = false;
        if(currentUserString) {
            currentUser = JSON.parse(currentUserString);
        }
        return Promise.resolve(_.get(currentUser, 'role', 'not_authenticate'))
    },
};


export default authProvider;
